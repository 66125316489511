import Component from "../Engine/Core/Component";
import * as THREE from "three";


export default class TrailPathPoint extends Component
{
    pointLength=0.0
    Awake() {
        super.Awake();
        return;         
        let helper = new THREE.AxesHelper();
        helper.scale.copy(new THREE.Vector3(3, 3, 3));
        this.transform.add(helper)
    }
}