import Component from "../Engine/Core/Component";
import Utilities from "../Engine/Utilities";
import LiftObject from "../Player/LiftObject";
import CandyHelper from "../CandyHelper/CandyHelper";
import {gsap} from "gsap";
import ShadowMaker from "../Player/ShadowMaker";
import {Vector3} from "three";

export default class KusimStation extends Component {


    baseModelPos = new Vector3(-8.20675, 0.153389, 52.8037)
    bakemodelPos = new Vector3(-8.15893, 0.0, 48.0722)


    // Cylinder019:[-8.20675,0.153389,55.0729,0,0,0,1.0,1.0,1.0],


    helperpositions =
        {



            ecdispos:[-9.73082,0.223056,51.3093,0,0,0,1.0,1.0,1.0],
            steerpos:[-8.15893,0.223056,53.0146,0,0,0,1.0,1.0,1.0],
            throttlepos:[-9.41458,0.223056,53.6293,0,0,0,1.0,1.0,1.0],
            swallowpos:[-11.2537,0.223056,52.0377,0,0,0,1.0,1.0,1.0],
            captainPos003:[-10.1191,0.223056,54.9887,0,0,0,1.0,1.0,1.0],
            captainPos:[-10.0536,0.223056,52.6422,0,0,0,1.0,1.0,1.0],
            captainPos005:[-6.81032,0.223056,51.2889,0,0,0,1.0,1.0,1.0],
            captainPos001:[-6.44035,0.223056,52.895,0,0,0,1.0,1.0,1.0],
            captainPos002:[-6.27227,0.223056,54.8594,0,0,0,1.0,1.0,1.0],
            captainPos004:[-9.58139,0.223056,52.3331,0,0,0,1.0,1.0,1.0],


            captainposes: []
        }


    ChartPos = [-6.09935, 1.01681, 51.3311, 0, 0, 0, 1.0, 1.0, 1.0];

    ChartTargetPos = [0, 0.5, .2, -1, 0, 0, 1, 1, 1];
    /*
    iconstartpos = [2.27155,0.5,27.8175, 0, 0, -1.5, 1.49343, 1.49343, 1.49343]
    icontargetpos = [-0.25, 1, 0.3, -1, 0, 0,0.5, 0.5, 0.5]
     */
    stationEnabled = false

    BuildStation() {
        this.helperpositions.captainposes = [
            this.helperpositions.captainPos,
            this.helperpositions.captainPos001,
            this.helperpositions.captainPos002,
            this.helperpositions.captainPos003,
            this.helperpositions.captainPos004,
            this.helperpositions.captainPos005
        ]

        let station = this;
        let spawnpos = this.baseModelPos;
        let kusimmodel = this.levelmanager.modelDatabase.database.kusimbase.scene.clone();
        let kusimmodelbake = this.levelmanager.modelDatabase.database.kusimbake.scene.clone();
        this.transform.add(kusimmodel);
        this.transform.add(kusimmodelbake);
        kusimmodel.position.copy(spawnpos)
        kusimmodelbake.position.copy(this.bakemodelPos)

        kusimmodel.children[1].children[0].renderOrder = -1
        kusimmodel.children[2].children[0].renderOrder = 0


        let chartmodel = Utilities.SpawnWithComponent("chartmodel", LiftObject, this.entitiy.world).Build(
            'kusimchart', this.ChartPos, this.ChartTargetPos, false
        );


        let ecdishelper = Utilities.SpawnWithComponent("EcdisHelper", CandyHelper, this.entitiy.world);
        ecdishelper.transform.position.set(
            this.helperpositions.ecdispos[0],
            this.helperpositions.ecdispos[1],
            this.helperpositions.ecdispos[2]
        )
        ecdishelper.transform.rotation.y = 3.1415 * -0.5;
        this.transform.add(ecdishelper.transform)
        ecdishelper.CreateDelayTask(1);
        ecdishelper.CreateConditionWaitTask(station, 'stationEnabled', () => {
            ecdishelper.animationController.animation.PlayFade('kusimecdis', 0.25)
            ecdishelper.rotationspeed = 58;
        });


        ecdishelper.CreateMoveToPointTask(() => {
            return Utilities.DataToTransform(station.ChartPos).v3;
        }, 1, 3, () => {
            ecdishelper.animationController.animation.PlayFade('run', 0.25)
        });
        ecdishelper.CreateDelayTask(0.5, () => {
            ecdishelper.animationController.animation.PlayFade('liftoff', 0.25)
            ecdishelper.transform.attach(chartmodel.model)

            chartmodel.ReAssignMaterials();
            gsap.to(chartmodel.model.position, {x: 0, y: 1, z: 0})

        });
        //Move To Carrier 
        ecdishelper.CreateMoveToPointTask(() => {
            return station.levelmanager.tug.GetCarrier(2).transform.position
        }, 2 * 2, 3, () => {
            ecdishelper.animationController.animation.PlayFade('liftoffrun', 0.25)
        })
        //Throw to target 
        ecdishelper.CreateDelayTask(0.4, () => {
            ecdishelper.animationController.animation.PlayFade('throw', 0.25)
        });
        ecdishelper.CreateDelayTask(0.5, () => {
            let carrier = station.levelmanager.tug.GetCarrier(2)
            carrier.transform.children[0].attach(chartmodel.model)
            chartmodel.MoveToTarget()
            ecdishelper.animationController.animation.PlayFade('throw', 0.25)
        });
        ecdishelper.CreateMoveToPointTask(() => {
            return new Vector3(
                station.helperpositions.ecdispos[0],
                station.helperpositions.ecdispos[1],
                station.helperpositions.ecdispos[2]
            )
        }, .1, -1, () => {
            ecdishelper.movespeed = 2;
            ecdishelper.animationController.animation.PlayFade('walk', 0.25)
        })
        let delay = {condition: false}
        ecdishelper.CreateConditionWaitTask(delay, 'condition', () => {
            ecdishelper.animationController.animation.PlayFade('kusimecdis', 0.25)
            ecdishelper.transform.position.set(
                this.helperpositions.ecdispos[0],
                this.helperpositions.ecdispos[1],
                this.helperpositions.ecdispos[2]
            )
            ecdishelper.transform.rotation.y = 3.1415 * -0.5;
        });


        let steerhelper = Utilities.SpawnWithComponent("SteerHelper", CandyHelper, this.entitiy.world);
        steerhelper.transform.position.set(
            this.helperpositions.steerpos[0],
            this.helperpositions.steerpos[1],
            this.helperpositions.steerpos[2]
        )

        this.transform.add(steerhelper.transform)
        steerhelper.CreateDelayTask(1);
        steerhelper.CreateConditionWaitTask(station, 'stationEnabled', () => {
            steerhelper.animationController.animation.PlayFade('kusimsteer', 0.25)
        });

        let throttlehelper = Utilities.SpawnWithComponent("Throttlehelper", CandyHelper, this.entitiy.world);
        throttlehelper.transform.position.set(
            this.helperpositions.throttlepos[0],
            this.helperpositions.throttlepos[1],
            this.helperpositions.throttlepos[2]
        )

        this.transform.add(throttlehelper.transform)
        throttlehelper.CreateDelayTask(1);
        throttlehelper.CreateConditionWaitTask(station, 'stationEnabled', () => {
            throttlehelper.animationController.animation.PlayFade('kusimthrottle', 0.25)
        });

        let swallowHelper = Utilities.SpawnWithComponent("swallowHelper", CandyHelper, this.entitiy.world);
        swallowHelper.transform.position.set(
            this.helperpositions.swallowpos[0],
            this.helperpositions.swallowpos[1],
            this.helperpositions.swallowpos[2]
        )

        this.transform.add(swallowHelper.transform)
        swallowHelper.CreateDelayTask(1);
        swallowHelper.CreateConditionWaitTask(station, 'stationEnabled', () => {
            swallowHelper.animationController.animation.PlayFade('kusimswallow', 0.25)
        });


        let captain = Utilities.SpawnWithComponent("captain", CandyHelper, this.entitiy.world);
        captain.transform.position.set(
            this.helperpositions.captainposes[0][0],
            this.helperpositions.captainposes[0][1],
            this.helperpositions.captainposes[0][2]
        )

        this.transform.add(captain.transform)
        captain.rotationspeed = 32;
        captain.movespeed = 2;
        captain.CreateDelayTask(0.25);


        for (let i = 0; i < station.helperpositions.captainposes.length; i++) {

            captain.CreateDelayTask(3, () => {
                    captain.animationController.animation.PlayFade('idle', 0.25)
                }, () => {
                    captain.animationController.animation.PlayFade('walk', 0.25)
                }
            );

            captain.CreateMoveToPointTask(() => {
                return Utilities.DataToTransform(station.helperpositions.captainposes[i]).v3;
            }, 0.05);


        } 

    }

    StartStationAnim() {
        if (this.stationEnabled)
            return;
        this.levelmanager.tug.CreateCarriers(1)
        this.stationEnabled = true;

    }
}