import Component from "../Engine/Core/Component";
import Utilities from "../Engine/Utilities";
import PlusParticleSystem from "./PlusParticleSystem";
import LiftObject from "../Player/LiftObject";
import CandyHelper from "../CandyHelper/CandyHelper";
import {gsap} from "gsap";

export default class UnrealStation extends Component {
    levelmanager
    stationEnabled
    plusplusPoses = [-59.2429,4.90897,44.3021,-58.963,4.86122,44.3012,-58.6828,4.816,44.3002,-58.4022,4.77331,44.2993,-58.1212,4.73316,44.2984,-57.8399,4.69556,44.2974,-57.5582,4.6605,44.2965,-57.2762,4.62799,44.2955,-56.994,4.59803,44.2946,-56.7115,4.57062,44.2936,-56.4287,4.54577,44.2927,-56.1458,4.52348,44.2917,-55.8626,4.50374,44.2908,-55.5793,4.48657,44.2898,-55.2959,4.47196,44.2889,-55.0123,4.45991,44.2879,-54.7286,4.45043,44.287,-54.4449,4.44351,44.286,-54.1611,4.43916,44.2851,-53.8773,4.43738,44.2842,-53.5934,4.43816,44.2832,-53.3096,4.4415,44.2823,-53.0259,4.44742,44.2813,-52.7422,4.4559,44.2804,-52.4586,4.46694,44.2794,-52.1751,4.48055,44.2785,-51.8917,4.49672,44.2775,-51.6085,4.51545,44.2766,-51.3255,4.53674,44.2756,-51.0427,4.56059,44.2747,-50.7601,4.58699,44.2738,-50.4778,4.61595,44.2728,-50.1957,4.64746,44.2719,-49.914,4.68152,44.2709,-49.6326,4.71813,44.27,-49.3515,4.75728,44.269,-49.0707,4.79896,44.2681,-48.7904,4.84319,44.2671]
        
    plusplusPoses2 = [-59.2429,4.90897,44.9267,-58.963,4.86122,44.9258,-58.6828,4.816,44.9248,-58.4022,4.77331,44.9239,-58.1212,4.73316,44.923,-57.8399,4.69556,44.922,-57.5582,4.6605,44.9211,-57.2762,4.62799,44.9201,-56.994,4.59803,44.9192,-56.7115,4.57062,44.9182,-56.4287,4.54577,44.9173,-56.1458,4.52348,44.9163,-55.8626,4.50374,44.9154,-55.5793,4.48657,44.9144,-55.2959,4.47196,44.9135,-55.0123,4.45991,44.9125,-54.7286,4.45043,44.9116,-54.4449,4.44351,44.9107,-54.1611,4.43916,44.9097,-53.8773,4.43738,44.9088,-53.5934,4.43816,44.9078,-53.3096,4.44151,44.9069,-53.0259,4.44742,44.9059,-52.7422,4.4559,44.905,-52.4586,4.46694,44.904,-52.1751,4.48055,44.9031,-51.8917,4.49672,44.9021,-51.6085,4.51545,44.9012,-51.3255,4.53674,44.9002,-51.0427,4.56059,44.8993,-50.7601,4.587,44.8984,-50.4778,4.61595,44.8974,-50.1957,4.64746,44.8965,-49.914,4.68152,44.8955,-49.6326,4.71813,44.8946,-49.3515,4.75728,44.8936,-49.0707,4.79897,44.8927,-48.7904,4.84319,44.8917]


    objectStartPositions = {


        UnrealIcon:[-40.1277,0.126872,43.8504,0,0,0,1.0,1.0,1.0],
        BlueprintIcon:[-38.3293,0.184411,43.8414,0,0,0,1.0,1.0,1.0],
        IconHelperPos:[-40.417,0.197268,45.7192,0,0,0,1.0,1.0,1.0],
        UnrealC:[-48.1616,4.69734,44.8044,0,0,0,1.0,1.0,1.0], 
        StationTransform:[-39.2564,4.65088e-06,41.8749,0,0,0,1.0,1.0,1.0], 
    
    }
    objectTargetPositions = {
        UnrealC: [-0.3744933, 1.45, -0.2132371, -3.12006, 0.566292, 0.0, 0.5, .5, .5],
        UnrealIcon: [0.13, 1.10421, 0, 1.5, 0, -1.5, 1, 1, 1],
        BlueprintIcon: [-0.25, 1, 0.3, -1, 0, 0,0.5, 0.5, 0.5]
    }
    plusparticle
    unrealC

    BuildStation() {
        let station = this;
        let stationModel = this.entitiy.world.modelDatabase.database.Station1.scene.clone();
        this.transform.add(stationModel);
        Utilities.DataToTransformApply(stationModel, this.objectStartPositions.StationTransform);

        let poleModels = this.entitiy.world.modelDatabase.database.UnrealPoles.scene.clone();
        this.transform.add(poleModels);

        this.plusparticle = Utilities.SpawnWithComponent("PlusParticleSystem", PlusParticleSystem, this.entitiy.world)
        this.plusparticle.levelmanager = this.levelmanager;
        this.plusplusPoses2.forEach(e => this.plusplusPoses.push(e))
        this.plusparticle.Build(this.plusplusPoses);


        this.unrealC = Utilities.SpawnWithComponent("unrealC", LiftObject, this.entitiy.world).Build(
            'UnrealC', this.objectStartPositions.UnrealC, this.objectTargetPositions.UnrealC, false
        );
        let unrealIcon = Utilities.SpawnWithComponent("UnrealIcon", LiftObject, this.entitiy.world).Build(
            'UnrealIcon', this.objectStartPositions.UnrealIcon, this.objectTargetPositions.UnrealIcon, false
        );

        let unrealBPIcon = Utilities.SpawnWithComponent("unrealBPIcon", LiftObject, this.entitiy.world).Build(
            'UnrealBlueprintIcon', this.objectStartPositions.BlueprintIcon, this.objectTargetPositions.BlueprintIcon, false
        );


        let unrealHelper = Utilities.SpawnWithComponent("Candy_Helper_1", CandyHelper, this.entitiy.world);
        Utilities.DataToTransformApply(unrealHelper.transform, this.objectStartPositions.IconHelperPos);
        unrealHelper.transform.rotation.y += Math.PI
        unrealHelper.rotationspeed=36
        unrealHelper.CreateDelayTask(1);
        unrealHelper.CreateConditionWaitTask(station, 'stationEnabled', () => {
            unrealHelper.StartSleepAnim()
        });
        unrealHelper.CreateDelayTask(0.5, () => {
            unrealHelper.StopSleepAnim();
        });
        let iconhelperstartpos = unrealHelper.transform.position.clone();

        //LiftOff Target
        unrealHelper.CreateDelayTask(0.5, () => {
            unrealHelper.animationController.animation.PlayFade('liftoff', 0.25)
            unrealHelper.transform.attach(unrealIcon.model)
            gsap.to(unrealIcon.model.position, {x: 0, y: 1, z: 0})
            gsap.to(unrealIcon.model.rotation, {x: 0, y: 0, z: 0})
        });

        //Move To Carrier 
        unrealHelper.CreateMoveToPointTask(() => {
            return station.levelmanager.tug.GetCarrier(2).transform.position
        }, 2 * 2, 1.5, () => {
            unrealHelper.StopSleepAnim()
            unrealHelper.animationController.animation.PlayFade('liftoffrun', 0.25)
        })
        //Throw to target
        unrealHelper.CreateDelayTask(0.4, () => {
            unrealHelper.animationController.animation.PlayFade('throw', 0.25)
        });
        unrealHelper.CreateDelayTask(0.5, () => {
            let carrier = station.levelmanager.tug.GetCarrier(2)
            carrier.transform.attach(unrealIcon.model)
            unrealIcon.MoveToTarget()
            unrealHelper.animationController.animation.PlayFade('throw', 0.25)
        });

        //Move to BPIcon 
        unrealHelper.CreateMoveToPointTask(
            () => {
                return unrealBPIcon.model.position
            }, 0.1)

        //LiftOff BPIcon
        unrealHelper.CreateDelayTask(0.5, () => {
            unrealHelper.animationController.animation.PlayFade('liftoff', 0.25)
            unrealHelper.transform.attach(unrealBPIcon.model)
            gsap.to(unrealBPIcon.model.position, {x: 0, y: 1, z: 0})
            gsap.to(unrealBPIcon.model.rotation, {x: 0, y: 0, z: 0})
        });

        //Move To Carrier 
        unrealHelper.CreateMoveToPointTask(() => {
            return station.levelmanager.tug.GetCarrier(2).transform.position
        }, 2 * 2, 1.5, () => { 
            unrealHelper.animationController.animation.PlayFade('liftoffrun', 0.25)
        })

        //Throw to target
        unrealHelper.CreateDelayTask(0.4, () => {
            unrealHelper.animationController.animation.PlayFade('throw', 0.25)
        });
        unrealHelper.CreateDelayTask(0.5, () => {
            let carrier = station.levelmanager.tug.GetCarrier(2)
            carrier.transform.attach(unrealBPIcon.model)
            unrealBPIcon.MoveToTarget()
            unrealHelper.animationController.animation.PlayFade('throw', 0.25)
        }); 

        //Return To sleepPos
        unrealHelper.CreateMoveToPointTask(() => {
            return iconhelperstartpos
        }, 0.5, -1, () => {
            unrealHelper.movespeed *= 0.15
            unrealHelper.animationController.animation.PlayFade('walk', 0.25)
        })
        let looper = {condition: false}
        unrealHelper.CreateConditionWaitTask(looper, 'condition', () => {
            unrealHelper.StartSleepAnim()
        });


        //this.levelmanager.tug.CreateCarriers(5)
        //this.StartCppAnim()
        //this.levelmanager.tug.GetCarrier(3).transform.attach(unrealIcon.model);
        //unrealIcon.MoveToTarget();
    }

    StartCppAnim() {
        if (this.plusparticle.followTransform !== undefined)
            return 0;
        
        this.plusparticle.followTransform = this.levelmanager.tug.GetCarrier(2).transform;
        this.plusparticle.CircleOnTarget(50,13)
        this.levelmanager.tug.GetCarrier(2).transform.attach(this.unrealC.model);
        this.unrealC.MoveToTarget();
    }

    StartStationAnim() {
        if (this.stationEnabled)
            return;
        //this.levelmanager.tug.CreateCarriers(1)
        this.stationEnabled = true; 
    } 
}