import Component from "../Engine/Core/Component";
import Utilities from "../Engine/Utilities";
import TrailPathPoint from "./TrailPathPoint";
import {Vector3} from "three";


export default class TrailPathComputer extends Component {
    PathPoints = []

    CreatePreDefinedPathPoints(predefinedPoints) {
        let count = predefinedPoints.length;
        for (let i = 0; i < count; i += 3) {
            this.AddPathPointToStart(
                new Vector3(
                    predefinedPoints[i],
                    predefinedPoints[i + 1],
                    predefinedPoints[i + 2]
                )
            )
        }
    }
    AddPathPointToStart(position,rotation) {
        let newPoint = this.CreateNewPathpoint(position,rotation);
        if (this.PathPoints.length >= 1) {
            if(rotation===undefined)
            {
                let lookDir = position.clone().sub(this.PathPoints[0].transform.position).normalize();
                lookDir.add(position); 
                newPoint.transform.lookAt(lookDir)
                this.PathPoints[0].transform.lookAt(position)
            }           
            newPoint.pointLength = this.PathPoints[0].transform.position.distanceTo(position)            
           
            
        }
        this.PathPoints.unshift(newPoint);
    }
    CreateNewPathpoint(position,rotation) {
        let newpoint = Utilities.SpawnWithComponent(
            "Trail Path Point",
            TrailPathPoint,
            this.world
        )
        newpoint.transform.position.copy(position) 
        if(rotation!==undefined)
            newpoint.transform.rotation.copy(rotation);
        this.transform.add(newpoint.transform)
        return newpoint;
    }

    
    GetPathLength()
    {
        let totalLength=0;
        this.PathPoints.forEach((e)=>
        {
            totalLength+=e.pointLength;
        })
        return totalLength;
    }
}