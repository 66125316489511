import './style.css'
import * as THREE from 'three'
import GameWorld from "./Engine/Core/GameWorld";
import * as dat from "lil-gui"
import LevelManager from "./Core/LevelManager";
import {TWEEN} from "three/examples/jsm/libs/tween.module.min";
import Entity from "./Engine/Core/Entity";
import Utilities from "./Engine/Utilities";
import {gsap} from "gsap/gsap-core";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

/** Html **/


//Dropdown menus

for (let i = 0; i < document.styleSheets.length; i++) {
    let sheet = document.styleSheets[i];
    let elementRules;
    for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].selectorText === '.DropMenuRoot .DropMenuItems.visible') {
            elementRules = sheet.cssRules[i];
        }
    }
    if (elementRules !== undefined)
        elementRules.style.maxHeight = `${window.innerHeight * 0.85}750px`;
}

let dropDownMenus = document.getElementsByClassName("DropMenuRoot");
for (let i = 0; i < dropDownMenus.length; i++) {
    let menuRoot = dropDownMenus[i];

    const ItemsRoot = menuRoot.getElementsByClassName('DropMenuItems')[0]
    const ItemsButton = menuRoot.getElementsByClassName('ToggleButton')[0]

    ItemsButton.addEventListener('click', () => {
        ToggleSkillsUI()
    })
    ItemsButton.addEventListener('touch', () => {
        ToggleSkillsUI()
    })
    const SkillsUI = {ison: false}

    function ToggleSkillsUI() {
        SkillsUI.ison = !SkillsUI.ison;

        let skills = ItemsRoot.children;
        let scount = ItemsRoot.childElementCount;
        if (SkillsUI.ison) {
            ItemsRoot.classList.add('visible');
            for (let i = 0; i < scount; i++) {
                let fillbar = skills[i].getElementsByClassName('FillBar')[0];
                let delay = i * 0.075;
                if (fillbar !== undefined) {
                    let targetPercent = fillbar.getAttribute('skillpercent') + "%";
                    if (targetPercent !== undefined) {
                        gsap.fromTo(fillbar, {width: 0}, {width: targetPercent, delay: 1, duration: 1})
                    }
                }
                gsap.fromTo(skills[i], {scale: 0, opacity: 0}, {
                    scale: 1,
                    opacity: 1,
                    duration: .25,
                    ease: "back.out",
                    delay: delay
                })

            }


        } else {
            ItemsRoot.classList.remove('visible');
        }


    }


}


//SkillsPanel


const loadingManager = new THREE.LoadingManager();


loadingManager.onStart = () => {
    console.log("On Start")
}
loadingManager.onLoad = () => {
    console.log("On load")
}
loadingManager.onProgress = () => {
    console.log("OnProgress")
}


const textureLoader = new THREE.TextureLoader(loadingManager);


/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Object
 */
const geometry = new THREE.BoxGeometry(0.1, 0.1, 0.1)
const material = new THREE.MeshStandardMaterial()

const mesh = new THREE.Mesh(geometry, material)
scene.add(mesh)

/**
 * Sizes
 */
const ScreenSize = {
    width: window.innerWidth,
    height: window.innerHeight,
    PixelRatio:window.devicePixelRatio,
    
} 
window.addEventListener('resize', () => {
    // Update sizes
    ScreenSize.width = window.innerWidth
    ScreenSize.height = window.innerHeight

    // Update camera
    camera.aspect = ScreenSize.width / ScreenSize.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(ScreenSize.width, ScreenSize.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
   
}) 
/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(35, ScreenSize.width / ScreenSize.height, 0.1, 100)
//const camera = new THREE.OrthographicCamera(sizes.width/-75,sizes.width/75,sizes.height/75,sizes.height/-75,1,1000)
camera.position.x = 0
camera.position.y = 15
camera.position.z = -17.5

const camRoot = new THREE.Group();
camera.lookAt(camRoot.position)
camRoot.add(camera)
//camRoot.rotateY(-45)
scene.add(camRoot)


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(ScreenSize.width, ScreenSize.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()


function setupKeyLogger() {
    document.onkeydown = function (e) {
        console.log(e.keyCode);
    }
}

setupKeyLogger()

const debug = window.location.hash === '#debug';
const gui = new dat.GUI()
if (!debug)
    gui.hide()
const gameworld = new GameWorld();
gameworld.InitWorld(scene,renderer, gui, camera, document, ScreenSize, window, debug)
const levelManagerEntity = new Entity(gameworld, "LevelManager");
const levelManager = levelManagerEntity.AddComponent(new LevelManager());
levelManager.world = gameworld
levelManager.camera = camera
levelManager.cameraRoot = camRoot;
levelManager.document = document
levelManager.StartLevel()


/*const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
levelManager.orbitcontrols=controls;*/

const ambientParameters = {
    color: '#9AFE7A',
    intensity: 1
}
if(debug) {
    gui
        .addColor(ambientParameters, 'color')
        .onChange(() => {
            ambientLight.color.set(ambientParameters.color)
        })

    gui.add(ambientParameters, 'intensity')
        .onChange(() => ambientLight.intensity = ambientParameters.intensity)
        .min(0)
        .max(1)


    const UIDParameters = {
        ID: "--"
    }
    gui.add(UIDParameters, 'ID').listen()
    var obj = {
        GenerateGUID: function () {
            UIDParameters.ID = Utilities.create_UUID()
            console.log(UIDParameters.ID)
        }
    };
    gui.add(obj, 'GenerateGUID');
}

//Model Test
/*const buffergeo = new BufferGeometry();
const indicies = []
const vertices = []

for (let i = 0; i < 65536; i++) {
    let p1 = new Vector3(-1, i * 1.001, 0);
    let p2 = new Vector3(1, i * 1.001, 0);
    vertices.push(p1.x);
    vertices.push(p1.y);
    vertices.push(p1.z);

    vertices.push(p2.x);
    vertices.push(p2.y);
    vertices.push(p2.z);

    if (i > 0) {
        indicies.push(i * 2)
        indicies.push(i * 2 + 1)
        indicies.push(i * 2 - 2)


        indicies.push(i * 2 - 1)
        indicies.push(i * 2 - 2)
        indicies.push(i * 2 + 1)


    }
}

 
const verticiesAttribute = new Float32BufferAttribute(vertices, 3);

buffergeo.setIndex(indicies);
buffergeo.setAttribute('position', verticiesAttribute);
buffergeo.setDrawRange(0, 18);
const materialtest = new MeshStandardMaterial();
materialtest.wireframe = true;
const testmesh = new Mesh(buffergeo, materialtest);
scene.add(testmesh);

const geoDrawProperty = {
    renderTarget: 10,
    xPosOffset:0
}
gui.add(geoDrawProperty,'renderTarget',0,15,1).name('asd').onChange(e=>
{
    buffergeo.setDrawRange(0, geoDrawProperty.renderTarget*6);
})
gui.add(geoDrawProperty,'xPosOffset',-15,15).onChange(e=>
{
    buffergeo.attributes.position.array[3*3]=e;
    buffergeo.attributes.position.array[5*3]=e;
    buffergeo.attributes.position.needsUpdate = true; 
})*/


let then = 0;
let lastframeTime;
const tick = (now) => {


    //now *= 0.005;  // convert to seconds
    //let deltaTime = now - then;
    //then = now;


    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - lastframeTime;
    lastframeTime = elapsedTime;
    gameworld.UpdateWorld(deltaTime)
    TWEEN.update()


    renderer.render(scene, camera)

    window.requestAnimationFrame(tick)

}

tick(1)


