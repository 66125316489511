export default class ModelDatabase {
    modelsToLoad = [
        {
            name: 'TugMesh',
            path: "Models/Tug.glb"
        },
        {
            name: 'Station1',
            path: "Models/Station1.glb"
        },
        {
            name: 'Station2',
            path: "Models/Station2.glb"
        },
        {
            name: 'Ground1',
            path: "Models/Ground1.glb"
        },
        {
            name: 'water',
            path: "Models/water.glb"
        },
        {
            name: 'GrassGround',
            path: "Models/Grounds/GrassGround.glb"
        },
        {
            name: 'SeaGround',
            path: "Models/Grounds/SeaGround.glb"
        },
        {
            name: 'Tree1',
            path: "Models/Tree.glb"
        },
        {
            name: 'TrainStopper',
            path: "Models/TrainStopper.glb"
        },
        {
            name: 'RailPlank',
            path: "Models/RailPlank.glb"
        },
        {
            name: 'CarrierBase',
            path: "Models/Carrier.glb"
        },
        {
            name: 'CandyHelperModel',
            path: "Models/CandyHelper.glb"
        },
        {
            name: 'sleepz',
            path: "Models/sleepz.glb"
        },
        {
            name: 'PhotoshopIcon',
            path: "Models/StationAssets/PhotoshopAssets/PhotoshopIcon.glb"
        },
        {
            name: 'PhotoshopBrush',
            path: "Models/StationAssets/PhotoshopAssets/PhotoshopBrush.glb"
        },
        {
            name: 'PhotoshopBucket',
            path: "Models/StationAssets/PhotoshopAssets/PhotoshopBucket.glb"
        },
        {
            name: 'PhotoshopStamp',
            path: "Models/StationAssets/PhotoshopAssets/PhotoshopStamp.glb"
        },
        {
            name: 'PhotoshopText',
            path: "Models/StationAssets/PhotoshopAssets/PhotoshopText.glb"
        },
        {
            name: 'maxcylinder',
            path: "Models/StationAssets/MaxAssets/maxcylinder.glb"
        },
        {
            name: 'maxhedra',
            path: "Models/StationAssets/MaxAssets/maxhedra.glb"
        },
        {
            name: 'maxicon',
            path: "Models/StationAssets/MaxAssets/maxicon.glb"
        },
        {
            name: 'maxknot',
            path: "Models/StationAssets/MaxAssets/maxknot.glb"
        },
        {
            name: 'maxteapot',
            path: "Models/StationAssets/MaxAssets/maxteapot.glb"
        },
        {
            name: 'zbrushicon',
            path: "Models/StationAssets/Zbrush/zbrushicon.glb"
        },
        {
            name: 'substanceicon',
            path: "Models/StationAssets/substance/substanceicon.glb"
        },
        {
            name: 'kusimbase',
            path: "Models/StationAssets/Kusim/kusim.glb"
        },
        {
            name: 'kusimbake',
            path: "Models/StationAssets/Kusim/kusimbake.glb"
        },
        {
            name: 'kusimchart',
            path: "Models/StationAssets/Kusim/chart.glb"
        },
        {
            name: 'UnrealPoles',
            path: "Models/StationAssets/Unreal/Poles.glb"
        },
        {
            name: 'UnrealC',
            path: "Models/StationAssets/Unreal/UnrealC.glb"
        },
        {
            name: 'UnrealIcon',
            path: "Models/StationAssets/Unreal/UnrealIcon.glb"
        },
        {
            name: 'UnrealBlueprintIcon',
            path: "Models/StationAssets/Unreal/UnrealBlueprintIcon.glb"
        },
        {
            name: 'TruckSim',
            path: "Models/StationAssets/Simulations/TruckSim.glb"
        },
        {
            name: 'phaeton',
            path: "Models/StationAssets/Simulations/phaeton.glb"
        },
        {
            name: 'SnowPiercer',
            path: "Models/StationAssets/Simulations/snowpiercer.glb"
        },
        {
            name: 'FireSim',
            path: "Models/StationAssets/Simulations/fire.glb"
        },
        {
            name: 'ArcelikSim',
            path: "Models/StationAssets/Simulations/Arcelik.glb"
        },
        {
            name: 'ArcelikProduct',
            path: "Models/StationAssets/Simulations/ArcelikProduct.glb"
        },
        {
            name: 'UnigineIcon',
            path: "Models/StationAssets/Simulations/UnigineIcon.glb"
        },
        {
            name: 'simtug',
            path: "Models/StationAssets/Simulations/simTug.glb"
        },
        {
            name: 'simtmx200',
            path: "Models/StationAssets/Simulations/tmx200.glb"
        },
        {
            name: 'FIM92',
            path: "Models/StationAssets/Simulations/FIM92.glb"
        },
        {
            name: 'unityicon',
            path: "Models/StationAssets/Simulations/UnityIcon.glb"
        },
        {
            name: 'airbaloon',
            path: "Models/StationAssets/Freelance/airbaloon.glb"
        },
        {
            name: 'groundtexts',
            path: "Models/groundtexts.glb"
        },
        {
            name: 'GameIconBottom',
            path: "Models/GameIconBottom.glb"
        },
        {
            name: 'ThreejsModel',
            path: "Models/StationAssets/Threejs/threejsmodel.glb"
        },
        
        /// Level Props
        {
            name: 'berry1',
            path: "Models/LevelProps/berry1.glb"
        },
        {
            name: 'bush1',
            path: "Models/LevelProps/bush1.glb"
        },
        {
            name: 'bush2',
            path: "Models/LevelProps/bush2.glb"
        },
        {
            name: 'bush3',
            path: "Models/LevelProps/bush3.glb"
        },
        {
            name: 'dock',
            path: "Models/LevelProps/dock.glb"
        },
        {
            name: 'fence',
            path: "Models/LevelProps/fence.glb"
        },
        {
            name: 'house1',
            path: "Models/LevelProps/house1.glb"
        },
        {
            name: 'Lotus',
            path: "Models/LevelProps/Lotus.glb"
        },
        {
            name: 'pumpkin',
            path: "Models/LevelProps/pumpkin.glb"
        },
        {
            name: 'rock1',
            path: "Models/LevelProps/rock1.glb"
        },
        {
            name: 'rock2',
            path: "Models/LevelProps/rock2.glb"
        },
        {
            name: 'rock3',
            path: "Models/LevelProps/rock3.glb"
        },
        {
            name: 'rock4',
            path: "Models/LevelProps/rock4.glb"
        },
        {
            name: 'struct1',
            path: "Models/LevelProps/struct1.glb"
        },
        {
            name: 'tree1',
            path: "Models/LevelProps/tree1.glb"
        },
        {
            name: 'tree2',
            path: "Models/LevelProps/tree2.glb"
        },
        {
            name: 'tree3',
            path: "Models/LevelProps/tree3.glb"
        },
        {
            name: 'trunk',
            path: "Models/LevelProps/trunk.glb"
        },
        {
            name: 'watertower',
            path: "Models/LevelProps/watertower.glb"
        }
    ]
    database = {}
    world
    loadcounter = 0

    LoadDatabase() {
        let modelDatabase = this;
        this.modelsToLoad.forEach(model => {
            this.world.LoadGltfModel(model.path, (gltf) => {
                modelDatabase.database[model.name] = gltf;
                gltf.scene.traverse(function (child) {

                    if (child.isMesh) {  
                        if (modelDatabase.world.materialDatabase.Database[child.material.name] === undefined) {
                            let color = child.material.color;
                            child.material = modelDatabase.world.materialDatabase.Database.Undefined;
                            if (color !== undefined)
                                child.material.color = color;

                        } else {
                            child.material = modelDatabase.world.materialDatabase.Database[child.material.name];

                        }
                    }
                })
                modelDatabase.loadcounter++;
                if (modelDatabase.loadcounter >= modelDatabase.modelsToLoad.length)
                    modelDatabase.OnDatabaseLoadCompleted()
            })
        })
    }

    OnDatabaseLoadCompleted() {
    }


}