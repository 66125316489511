export default class CandyHelperTask
{
    startCondition=true;
    Update(delta)
    {
        
    }
    OnTaskComplete()
    {
        
    }
    OnTaskStart()
    {
        
    }
}