import Component from "../Engine/Core/Component";

export default class TrailTrigger extends Component {
    pathComputer
    triggerTargetPositioner = 0
    triggerLength = 0

    triggerPos
    triggered = false
    alreadyTriggered = false;
    triggerOnce=false

    
    TriggerStart() {
        if (this.triggered)
            return 0;  
        this.triggered = true;
        this.OnTriggerStart();
        this.alreadyTriggered=true;
    }

    TriggerEnd() {
        if (!this.triggered)
            return 0;
        this.triggered = false;
        this.OnTriggerEnd();
        if(this.triggerOnce)
            this.Update=undefined
            
    }

    OnTriggerStart = () => {}
    OnTriggerEnd = () => {}
 
    Update() { 
        if (this.triggerTargetPositioner === undefined)
            return 0;
        let tarPos = this.triggerTargetPositioner.positionOnPath;
        if (tarPos <= this.triggerPos && tarPos >= (this.triggerPos - this.triggerLength))
            this.TriggerStart();
        else
            this.TriggerEnd();
        
    }
    


}