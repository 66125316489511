import Component from "../Engine/Core/Component";
import TrailPathPositioner from "../Trail/TrailPathPositioner";
import {gsap} from "gsap"
import {MeshBasicMaterial,Color} from "three";

export default class TugStopper extends Component {
    model
    trailPositioner
    rotator
    RedLightMaterial
    GreenLightMaterial

    Build(Path, pathPosition) {
        this.model = this.entitiy.world.modelDatabase.database.TrainStopper.scene.clone();
        this.transform.add(this.model);
        this.model.position.x = -0.75
        this.trailPositioner = this.entitiy.AddComponent(new TrailPathPositioner());
        this.trailPositioner.lookback = true;
        this.trailPositioner.Build(Path);
        this.trailPositioner.PlaceOnPath(pathPosition);


        this.RedLightMaterial = new MeshBasicMaterial({color: '#f60606'});
        this.GreenLightMaterial = new MeshBasicMaterial({color: '#000000'});

        this.model.traverse((child) => {
            if (child.isMesh) {
                // console.log(child.material.name)
                if (child.material.name === 'StopperRedLight')
                    child.material = this.RedLightMaterial;
                if (child.material.name === 'StopperGreenLight')
                    child.material = this.GreenLightMaterial;
            }
            if (child.name === 'rotator') {
                this.rotator = child;
            }
        }); 
    }

    PlayOpenAnim(delay) {
        let stopper = this;
        gsap.to(this.rotator.rotation, {
            y: -3.1415, delay:delay,duration:1,
            onStart: () => {                
                stopper.GreenLightMaterial.color=new Color('#03f10f');
                stopper.RedLightMaterial.color=new Color('#000000');              
            },            
        })
    }


}