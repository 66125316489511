import Component from "../Engine/Core/Component";
import {BoxHelper} from "three";
import Utilities from "../Engine/Utilities";

export default class TrailPathPositioner extends Component {

    PathComputer
    positionOnPath = 0.0
    lookback=false
    updateRotation=true
    Awake() {
        super.Awake();
        return 
        //let sphere = new SphereGeometry();
        //let object = new Mesh(sphere, new MeshBasicMaterial(0xff0000));
        const box = new BoxHelper(object, 0xffff00);
        this.transform.add(box);
        box.position.set(0, 0, 0)

    }

    Build(pathcomputer) {
        this.PathComputer = pathcomputer;
    }

    PlaceOnPath(pathpos = 0.0) {
        if (this.PathComputer.PathPoints.length < 2)
            return

        let distancecounter = 0.0
        let selectedIndex = -1
        for (let i = 0; i < this.PathComputer.PathPoints.length; i++) {
            if (pathpos <= distancecounter) {
                selectedIndex = i - 1;
                break;
            }
            distancecounter += this.PathComputer.PathPoints[i].pointLength;
        }
        if (selectedIndex < 0)
            selectedIndex = this.PathComputer.PathPoints.length - 2;
        if (selectedIndex < 0)
            return;

        let frac = distancecounter - this.PathComputer.PathPoints[selectedIndex].pointLength;
        frac=pathpos-frac;
        
        let percent =   frac / this.PathComputer.PathPoints[selectedIndex].pointLength;
 


        let pos = Utilities.LerpV3WithReturn(this.PathComputer.PathPoints[selectedIndex].transform.position,
            this.PathComputer.PathPoints[selectedIndex + 1].transform.position,
            percent
        ) 
        this.transform.position.copy(pos)
        this.positionOnPath=pathpos;       
        if(!this.updateRotation)
            return;
        
        if(this.lookback) {
            let lookPos=this.PathComputer.PathPoints[selectedIndex + 1].transform.position.clone();
            lookPos.sub(this.transform.position);
            lookPos.multiplyScalar(-1)
            lookPos.add(this.transform.position)            
            this.transform.lookAt(lookPos)
        }else
        {
            this.transform.lookAt(this.PathComputer.PathPoints[selectedIndex + 1].transform.position)
        }

    }


} 