import Component from "../Engine/Core/Component";
import Utilities from "../Engine/Utilities";
import CPUParticleEmitter from "../Engine/Components/ParticleSystem/CPUParticleEmitter";
import {SphereGeometry, Vector2} from "three";

export default class PotSmoker extends Component {
    particleEmitter
    boiling = false
    capModel
timer=0
    base
    Build(topmodel, potbase) {
        this.base=potbase
        this.particleEmitter = Utilities.SpawnWithComponent("Boiler Smoke", CPUParticleEmitter, this.entitiy.world);
        potbase.add(this.particleEmitter.transform)
        this.capModel = topmodel;
        this.particleEmitter.transform.position.y = 0.35;
        this.particleEmitter.transform.position.z = 0.44;
        this.particleEmitter.poolsize = 10
        this.particleEmitter.emitPeriod = 99999999;
        this.particleEmitter.sizeRange = new Vector2(0.05, 0.1)
        this.particleEmitter.InitEmitter(new SphereGeometry(1, 12, 12), this.world.materialDatabase.Database.TugSmoke); 
    }

    Update(delta) {
        if (!this.boiling)
            return 0
        this.timer+=delta;       
        
        this.capModel.position.y=Math.sin(this.timer*25)*0.025
    }

    StartBoiling() {
        this.boiling = true;
        this.particleEmitter.emitPeriod = 0.25;
    }

}