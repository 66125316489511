import Component from "../Engine/Core/Component";
import {gsap} from 'gsap'

export default class informationPoint extends Component {
    camera
    levelmanager
    panel
    UIPoint
    UIPointLabel
    ShowOnUI = false
    closeButton

    Build(PanelID) {

        this.closeButton = this.entitiy.world.document.createElement('button');
        this.closeButton.classList.add('CloseButton')
        this.UIPoint = this.entitiy.world.document.getElementById("infoPointBase")
        this.UIPoint = this.UIPoint.cloneNode(true);
        this.UIPoint.removeAttribute('id');
        this.panel = this.entitiy.world.document.getElementById(PanelID);
        let container = this.entitiy.world.document.getElementById('InformationPointsContainer');
        container.appendChild(this.UIPoint)
        this.entitiy.world.document.getElementById('CloseButtonContainer').appendChild(this.closeButton)
        let infopoint = this;
        this.UIPoint.onclick = () => {
            infopoint.OpenPanel()
        }
        this.UIPoint.ontouch = () => {
            infopoint.OpenPanel()
        }

        this.closeButton.onclick = () => {
            infopoint.ClosePanel()
        }
        this.closeButton.ontouch = () => {
            infopoint.ClosePanel()
        }
        this.UIPointLabel = this.UIPoint.getElementsByClassName('label')[0];
        this.closeButton.style.display = 'none'
        this.panel.style.display = 'none'
        this.camera = this.entitiy.world.camera;

    }

    EnableUIPoint() {
        this.ShowOnUI = true;
        this.UIPoint.style.display = 'block'

        gsap.fromTo(this.UIPointLabel, {scale: 0}, {scale: 1, duration: 1, ease: 'elastic'})
        gsap.fromTo(this.UIPointLabel, {rotation: 0}, {rotation: 359, duration: 1, ease: 'elastic'});
    }

    DisableUIPoint() {
        this.ShowOnUI = false;

        gsap.fromTo(this.UIPointLabel, {scale: 1}, {
            scale: 0, duration: .25, onComplete: () => {
                this.UIPoint.style.display = 'none'
            }
        })
        //this.UIPoint.classList.remove('visible')
    }

    OpenPanel() {
        //console.log("OpenPanel")
        //this.panel.classList.add('visible')
        this.closeButton.style.display = 'block'
        this.panel.style.display = 'block'
        this.panel.style.opacity = 0
        gsap.to(this.panel.style, {opacity: 1, duration: 1.5})
    }

    ClosePanel() {
        this.panel.style.opacity = 1
        this.closeButton.style.display = 'none'
        gsap.to(this.panel.style, {
            opacity: 0, duration: .25, onComplete: () => {
                this.panel.style.display = 'none'

            }
        })
    }

    Update(delta) {
        if (!this.ShowOnUI)
            return 0;
        let screenPosition = this.transform.position.clone()
        screenPosition.project(this.camera)

        const translateX = screenPosition.x * this.entitiy.world.ScreenSize.width * 0.5
        const translateY = -screenPosition.y * this.entitiy.world.ScreenSize.height * 0.5
        this.UIPoint.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
    }
}