import Component from "../Engine/Core/Component";
import {BufferGeometry, Float32BufferAttribute, Group, MathUtils, Mesh, MeshMatcapMaterial, Vector3} from "three";
import {gsap} from "gsap/gsap-core";

export default class GameDevelopmentStation extends Component {
    levelmanager
    stationEnabled = false;
    uvX = 0
    uvY = 0
    rowCount = 2;
    columnCount = 44;
    planks = []

    placementX = 0;
    placementY = 0;
    placementZ = 0;
    placementGroup

    BuildStation() {
        let leftPos = new Vector3(-69.7, 0.0, -19.873);
        let rightPos = new Vector3(-74, 0.0, -19.873);


        leftPos.x += 1;
        this.CreatePanels(leftPos)
        rightPos.z += (this.columnCount - 1);
        this.CreatePanels(rightPos, true)
    }

    CreatePanels(basePos, reverse = false) {
        let botModelRoot;
        this.levelmanager.modelDatabase.database.GameIconBottom.scene.traverse(e => {
            if (e.isMesh) {
                botModelRoot = e;
            }
        })
        for (let x = 0; x < this.rowCount; x++) {
            for (let y = 0; y < this.columnCount; y++) {

                let mesh = this.GetTopMesh(this.uvX, this.uvY, 10, 9);
                this.transform.add(mesh)
                mesh.position.copy(basePos)
                if (reverse)
                    mesh.position.set(basePos.x + x, 0, basePos.z - y)
                else
                    mesh.position.set(basePos.x + x, 0, basePos.z + y)
                mesh.add(botModelRoot.clone())
                mesh.scale.multiplyScalar(.9)
                this.planks.push(mesh)
                this.IncreasePanelIndex();
            }
        }
    }

    Update(delta) {
        if (!this.stationEnabled)
            return 0;
        if (this.planks.length === 0)
            return 0;
        let station = this;
        let targetCarrier = this.levelmanager.tug.GetLastCarrier();
        let tugPosZ = this.levelmanager.tug.transform.position.z;

        let selectedIndex = -1;
        for (let i = 0; i < this.planks.length; i++) {
            if (this.planks[i].position.z < tugPosZ) {
                selectedIndex = i;
                break;
            }
        }
        if (selectedIndex !== -1) {
            let selectedPlank = this.planks[selectedIndex];
            this.planks.splice(selectedIndex, 1);
            station.placementGroup.attach(selectedPlank)
            let ypos = this.placementY;
            let yposRnd = MathUtils.randFloat(0, 3);
            let zPos = this.placementZ;
            let xPos = this.placementX;
            let xPosRnd = MathUtils.randFloat(-3, 3);
            let startDuration = MathUtils.randFloat(0.5, 1.2);

            gsap.to(selectedPlank.position, {
                x: this.placementX+xPosRnd,
                y: (yposRnd+ypos + 25) * 0.4,
                z: zPos - 3,
                duration:startDuration, 
                onComplete: () => {
                    gsap.to(selectedPlank.position, {x:xPos,y: ypos * 0.4, z: zPos, duration: 0.5})
                }
            })
            gsap.to(selectedPlank.scale, {
                x: 1,
                y: 1,
                z: 1,
                delay: 0.5+startDuration
            })
            gsap.to(selectedPlank.rotation, {
                y: 3.1415,
                duration: 1.5,
            })
            this.IncreasePlacementIndex();
        }

    }

    IncreasePanelIndex() {
        this.uvY++;
        if (this.uvY >= 9) {
            this.uvY = 0;
            this.uvX++;

            if (this.uvX >= 10)
                this.uvX = 0;
        }
    }

    IncreasePlacementIndex() {
        this.placementZ++;
        if (this.placementZ >= 4) {
            this.placementZ = 0;
            this.placementX++;

            if (this.placementX >= 4) {
                this.placementX = 0;
                this.placementY++;

            }
        }
    }

    StartStationAnim() {
        if (this.stationEnabled)
            return 0;
        this.stationEnabled = true;
        this.levelmanager.tug.CreateCarriers(1)
        let lastCarrier = this.levelmanager.tug.GetLastCarrier();
        this.placementGroup = new Group();
        lastCarrier.transform.add(this.placementGroup)
        this.placementGroup.position.set(-.3, .65, -.35)
        this.placementGroup.scale.multiplyScalar(.2);


    }


    GetTopMesh(X, Y, rowCount, columnCount) {
        let geometry = new BufferGeometry();
        let indices = [0, 1, 3, 1, 2, 3]
        let normals = [
            0, 1, 0,
            0, 1, 0,
            0, 1, 0,
            0, 1, 0
        ]
        let vertices = [
            -1, 0, 1,
            1, 0, 1,
            1, 0, -1,
            -1, 0, -1
        ]
        let uv = [
            1, 0,
            0, 0,
            0, 1,
            1, 1
        ]
        for (let i = 0; i < 8; i += 2) {
            uv[i + 0] = (uv[i + 0] + X) / rowCount;
            uv[i + 1] = (uv[i + 1] + Y) / columnCount;
        }
        for (let i = 0; i < vertices.length; i++) {
            vertices[i] *= .5;
        }
        geometry.setIndex(indices);
        this.verticiesAttribute = new Float32BufferAttribute(vertices, 3);
        geometry.setAttribute('position', this.verticiesAttribute);
        geometry.setAttribute('normal', new Float32BufferAttribute(normals, 3));
        geometry.setAttribute('uv', new Float32BufferAttribute(uv, 2));

        let mesh = new Mesh(geometry,
            this.levelmanager.materialDatabase.Database.IconSheet
        );
        this.transform.add(mesh);
        return mesh;
    }
}