import Component from "../Engine/Core/Component";
import {Vector2} from "three"
import Utilities from "../Engine/Utilities";
export default class Controller extends Component {

    joysticInput=new Vector2(0,0);
    jpoint
    jPressed = false; 
    jPointPos={x:0,y:0}
   
   
    TouchMove(t)
    {
        if(!this.jPressed)
            return 0 
        this.MoveJoystick(new Vector2( t.changedTouches[0].clientX, t.changedTouches[0].clientY));
    }
    MouseMove(m) { 
        if(!this.jPressed)
            return 0;
        this.MoveJoystick(new Vector2(m.clientX,m.clientY));
    }
    
    MoveJoystick(pointPos)
    {
        pointPos.x -= this.jPointPos.x;
        pointPos.y -= this.jPointPos.y; 
        let len = pointPos.length(); 
       
        if(len>75)
        {
            pointPos.normalize().multiplyScalar(75);
        }
        this.jpoint.style.transform = `translateX(${pointPos.x}px) translateY(${pointPos.y}px)`
        this.joysticInput=pointPos.multiplyScalar(1/75); 
        
    }

    
    
    SetupJoystick() {

       
        let jc = this;
        this.jpoint = this.entitiy.world.document.getElementById("J_Point");
        this.Jbase  = this.entitiy.world.document.getElementById("J_Base");
        this.JBack  = this.entitiy.world.document.getElementById("J_Back");
        this.Jbase.style.display = 'block'
        
        this.jpoint.addEventListener('mousedown', () => {
            jc.jPressed = true;
            jc.jPointPos=  Utilities.GetClientPos(jc.jpoint);
            jc.jPointPos.x+=37.5;
            jc.jPointPos.y+=37.5;
            jc.JBack.classList.add("Move")
        })
        this.jpoint.addEventListener('touchstart', (e) => { 
            jc.jPressed = true;
            jc.jPointPos=  Utilities.GetClientPos(jc.jpoint);
            jc.jPointPos.x+=37.5;
            jc.jPointPos.y+=37.5;
            jc.JBack.classList.add("Move")
        })
        this.jpoint.addEventListener('mouseup', () => {
            jc.jPressed = false;
            jc.jpoint.style.transform = `translateX(${0}px) translateY(${0}px)`
            jc.joysticInput=new Vector2(0,0);
            jc.JBack.classList.remove("Move")
        })
        this.jpoint.addEventListener('touchend', () => {
            jc.jPressed = false;
            jc.jpoint.style.transform = `translateX(${0}px) translateY(${0}px)`
            jc.joysticInput=new Vector2(0,0);
            jc.JBack.classList.remove("Move")
        })
        this.entitiy.world.document.addEventListener('mouseup', () => {
            jc.jPressed = false;
            jc.jpoint.style.transform = `translateX(${0}px) translateY(${0}px)`
            jc.joysticInput=new Vector2(0,0);
            jc.JBack.classList.remove("Move")
        })
        this.entitiy.world.document.addEventListener('touchend', () => {
            jc.jPressed = false;
            jc.jpoint.style.transform = `translateX(${0}px) translateY(${0}px)`
            jc.joysticInput=new Vector2(0,0);
            jc.JBack.classList.remove("Move")
        })



        this.entitiy.world.document.addEventListener('mousemove', (me)=>
        {
            jc.MouseMove(me)
        }, false);
  this.entitiy.world.document.addEventListener('touchmove', (me)=>
        {
            jc.TouchMove(me)
        }, false);


        
        
        
        
        
        
        
        return 0;
        this.joystick = {}
        this.joystick.active = false


        this.joystick.$element = document.createElement('div')
        this.joystick.$element.style.userSelect = 'none'
        this.joystick.$element.style.position = 'fixed'
        this.joystick.$element.style.bottom = '10px'
        this.joystick.$element.style.left = '10px'
        this.joystick.$element.style.width = '170px'
        this.joystick.$element.style.height = '170px'
        this.joystick.$element.style.borderRadius = '50%'
        this.joystick.$element.style.transition = 'opacity 0.3s 0.0s'
        this.joystick.$element.style.willChange = 'opacity'
        this.joystick.$element.style.opacity = '0'
        // this.touch.joystick.$element.style.backgroundColor = '#ff0000'
        document.body.appendChild(this.joystick.$element)

        this.joystick.$cursor = document.createElement('div')
        this.joystick.$cursor.style.position = 'absolute'
        this.joystick.$cursor.style.top = 'calc(50% - 30px)'
        this.joystick.$cursor.style.left = 'calc(50% - 30px)'
        this.joystick.$cursor.style.width = '60px'
        this.joystick.$cursor.style.height = '60px'
        this.joystick.$cursor.style.border = '2px solid #ffffff'
        this.joystick.$cursor.style.borderRadius = '50%'
        this.joystick.$cursor.style.boxSizing = 'border-box'
        this.joystick.$cursor.style.pointerEvents = 'none'
        this.joystick.$cursor.style.willChange = 'transform'
        this.joystick.$element.appendChild(this.joystick.$cursor)

        this.joystick.$limit = document.createElement('div')
        this.joystick.$limit.style.position = 'absolute'
        this.joystick.$limit.style.top = 'calc(50% - 75px)'
        this.joystick.$limit.style.left = 'calc(50% - 75px)'
        this.joystick.$limit.style.width = '150px'
        this.joystick.$limit.style.height = '150px'
        this.joystick.$limit.style.border = '2px solid #ffffff'
        this.joystick.$limit.style.borderRadius = '50%'
        this.joystick.$limit.style.opacity = '0.25'
        this.joystick.$limit.style.pointerEvents = 'none'
        this.joystick.$limit.style.boxSizing = 'border-box'
        this.joystick.$element.appendChild(this.joystick.$limit)


        this.joystick.angle = {}

        this.joystick.angle.offset = Math.PI * 0.18

        this.joystick.angle.center = {}
        this.joystick.angle.center.x = 0
        this.joystick.angle.center.y = 0

        this.joystick.angle.current = {}
        this.joystick.angle.current.x = 0
        this.joystick.angle.current.y = 0

        this.joystick.angle.originalValue = 0
        this.joystick.angle.value = -Math.PI * 0.5

        // Resize
        this.joystick.resize = () => {
            const boundings = this.joystick.$element.getBoundingClientRect()
            this.joystick.angle.center.x = boundings.left + boundings.width * 0.5
            this.joystick.angle.center.y = boundings.top + boundings.height * 0.5
        }
        /* RESIZE DA
        this.sizes.on('resize', this.joystick.resize)
        this.joystick.resize()
        */
        this.joystick.Update = () => {
            // Joystick active
            if (this.joystick.active) {
                // Calculate joystick angle
                this.joystick.angle.originalValue = -Math.atan2(
                    this.joystick.angle.current.y - this.joystick.angle.center.y,
                    this.joystick.angle.current.x - this.joystick.angle.center.x
                )
                this.joystick.angle.value = this.joystick.angle.originalValue + this.joystick.angle.offset

                // Update joystick
                const distance = Math.hypot(this.joystick.angle.current.y - this.joystick.angle.center.y, this.joystick.angle.current.x - this.joystick.angle.center.x)
                let radius = distance
                if (radius > 20) {
                    radius = 20 + Math.log(distance - 20) * 5
                }
                if (radius > 43) {
                    radius = 43
                }
                const cursorX = Math.sin(this.joystick.angle.originalValue + Math.PI * 0.5) * radius
                const cursorY = Math.cos(this.joystick.angle.originalValue + Math.PI * 0.5) * radius
                this.joystick.$cursor.style.transform = `translateX(${cursorX}px) translateY(${cursorY}px)`

            }
        }


        // Events
        this.joystick.events = {}
        this.joystick.touchIdentifier = null
        this.joystick.events.touchstart = (_event) => {
            _event.preventDefault()
            const touch = _event.changedTouches[0]
            if (touch) {
                this.joystick.active = true

                this.joystick.touchIdentifier = touch.identifier

                this.joystick.angle.current.x = touch.clientX
                this.joystick.angle.current.y = touch.clientY

                this.joystick.$limit.style.opacity = '0.5'

                document.addEventListener('touchend', this.joystick.events.touchend)
                document.addEventListener('touchmove', this.joystick.events.touchmove, {passive: false})

                this.trigger('joystickStart')
            }
        }

        this.joystick.events.touchmove = (_event) => {
            _event.preventDefault()

            const touches = [..._event.changedTouches]
            const touch = touches.find((_touch) => _touch.identifier === this.joystick.touchIdentifier)

            if (touch) {
                this.joystick.angle.current.x = touch.clientX
                this.joystick.angle.current.y = touch.clientY

                this.trigger('joystickMove')
            }
        }

        this.joystick.events.touchend = (_event) => {
            const touches = [..._event.changedTouches]
            const touch = touches.find((_touch) => _touch.identifier === this.joystick.touchIdentifier)

            if (touch) {
                this.joystick.active = false

                this.joystick.$limit.style.opacity = '0.25'

                this.joystick.$cursor.style.transform = 'translateX(0px) translateY(0px)'

                document.removeEventListener('touchend', this.joystick.events.touchend)

                this.trigger('joystickEnd')
            }
        }

        this.joystick.$element.addEventListener('touchstart', this.joystick.events.touchstart, {passive: false})

        this.reveal = () => {
            this.joystick.$element.style.opacity = 1
        }
        this.reveal()
    }

    /*Update() {
        if (this.joystick !== undefined)
            this.joystick.Update();
    }*/
}