import * as THREE from 'three'
import Utilities from "../Engine/Utilities";
import Component from "../Engine/Core/Component";
import TrailPathPositioner from "../Trail/TrailPathPositioner";
import ShadowMaker from "./ShadowMaker";
import   gsap from "gsap";
export default class SnakeCarrier extends Component {

    cubeMesh
    world
    positioner

    constructor() {
        super()


    }

    Init() {

        super.Init();

        this.world = this.entitiy.world

        let carrier = this;

        this.positioner = this.entitiy.AddComponent(new TrailPathPositioner());
        carrier.cubeMesh= this.world.modelDatabase.database.CarrierBase.scene.clone();
        carrier.transform.add(carrier.cubeMesh)
        carrier.cubeMesh.position.y+=0.25
        carrier.ShadowMaker= Utilities.SpawnWithComponent("TugShadow", ShadowMaker, carrier.world);
        
        carrier.ShadowMaker.transform.position.y+=0.05
        carrier.transform.add(carrier.ShadowMaker.transform)
        carrier.ShadowMaker.Build(2,2)
           
    }
    PlaySpawnAnim(delay)
    { 
            gsap.fromTo(this.cubeMesh.scale,{x:0,y:0,z:0},{x:1,y:1,z:1,ease:'back.out(2.5)',delay:delay})
       
    }
 
    async spawnAnim() {
        this.cubeMesh.scale.copy(new THREE.Vector3(0, 0, 0))

        var spawnResolve = function (time) {
            return new Promise(resolve => {
                setTimeout(function () {
                    resolve("fast ");
                }, time);
            });
        };

        let timer = 0
        while (timer < 10) {
            timer++; 
            let scale = Utilities.EaseOutElastic(timer / 10.0); 
            this.cubeMesh.scale.copy(new THREE.Vector3(scale, scale, scale))
            await spawnResolve(50); 
        }

    }
}