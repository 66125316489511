import {
    MeshBasicMaterial,
    MeshMatcapMaterial,
    ShaderMaterial,
    Color,
    AdditiveBlending,
    RepeatWrapping,
    UniformsUtils, UniformsLib, Vector3
} from "three";
import GroundVertexShader from '../Shaders/Ground/Grass/vertex.glsl'
import GroundFragmentShader from '../Shaders/Ground/Grass/fragment.glsl'

import GroundSeaVertexShader from '../Shaders/Ground/Sea/vertex.glsl'
import GroundSeaFragmentShader from '../Shaders/Ground/Sea/fragment.glsl'

import AssetDefaultVertex from '../Shaders/Asset/Default/vertex.glsl'
import AssetDefaultFragment from '../Shaders/Asset/Default/fragment.glsl'

import ShadowVertex from '../Shaders/Shadow/vertex.glsl'
import ShadowFragment from '../Shaders/Shadow/fragment.glsl'

import OutlineVertex from '../Shaders/Outline/vertex.glsl'
import OutlineFragment from '../Shaders/Outline/fragment.glsl'

import TugSmokeVertex from '../Shaders/TugSmoke/vertex.glsl'
import TugSmokeFragment from '../Shaders/TugSmoke/fragment.glsl'

import SleepzVertex from '../Shaders/sleepz/vertex.glsl'
import SleepzFragment from '../Shaders/sleepz/fragment.glsl'

import KusimSeaVertex from '../Shaders/KusimSea/vertex.glsl'
import KusimSeaFragment from '../Shaders/KusimSea/fragment.glsl'

import KusimMaskVertex from '../Shaders/KusimMask/vertex.glsl'
import KusimMaskFragment from '../Shaders/KusimMask/fragment.glsl'

import ArcelikProductVertex from '../Shaders/ArcelikProduct/vertex.glsl'
import ArcelikProductFragment from '../Shaders/ArcelikProduct/fragment.glsl'

import GroundTextVertex from '../Shaders/GroundText/vertex.glsl'
import GroundTextFragment from '../Shaders/GroundText/fragment.glsl'
import LevelManager from "./LevelManager";
import Utilities from "../Engine/Utilities";

export default class MaterialDatabase {
    levelmanager
    timer = 0
    MaterialsToUpdateTime = []
    MaterialsToUpdateCamPos = []
    MaterialsToUpdateCamDir = []
    materialsToLoad = [
        {
            name: 'Undefined',
            shader: 'Unlit',
            color: 0x7ffff
        },
        {
            name: 'Station1',
            shader: 'Unlit',
            texture: '/textures/T_Station.jpg'
        },
        {
            name: 'Station2',
            shader: 'Unlit',
            texture: '/textures/T_Station2.jpg'
        },
        {
            name: 'groundgrassbasemat',
            shader: 'GroundGrass',
            shadowTexture: '/textures/T_GroundShadow.jpg'
        },
        {
            name: 'GroundGrass2',
            shader: 'AssetDefault',
            color: '#7dad31'
        },
        {
            name: 'GroundSea',
            shader: 'GroundSea'
        },
        {
            name: 'GroundRoad1',
            shader: 'AssetDefault',
            color: '#212121'
        },
        {
            name: 'GroundBridge1',
            shader: 'AssetDefault',
            color: '#2a1610'
        },
        {
            name: 'M_GroundText',
            shader: 'GroundText',
            color: '#c2b191'
        },
        {
            name: 'Tree1',
            shader: 'Unlit',
            texture: '/textures/Tree1.jpg'
        },
        {
            name: 'TestScene',
            shader: 'AssetDefault',
            texture: '/textures/matcaps/2.png'
        },
        {
            name: 'metal',
            shader: 'Matcap',
            texture: '/textures/matcaps/2.png'
        },
        {
            name: 'M2',
            shader: 'Matcap',
            texture: '/textures/matcaps/2.png'
        },
        {
            name: 'Matcap_Brown1',
            shader: 'Matcap',
            texture: '/textures/matcaps/Brown1.png'
        },
        {
            name: 'M_B1',
            shader: 'Matcap',
            texture: '/textures/matcaps/M_B1.jpg'
        },
        {
            name: 'M_B2',
            shader: 'Matcap',
            texture: '/textures/matcaps/M_B2.jpg'
        },
        {
            name: 'M_R1',
            shader: 'Matcap',
            texture: '/textures/matcaps/M_R1.jpg'
        },
        {
            name: 'M_R2',
            shader: 'Matcap',
            texture: '/textures/matcaps/M_R2.jpg'
        },
        {
            name: 'M_G1',
            shader: 'Matcap',
            texture: '/textures/matcaps/M_G1.jpg'
        },
        {
            name: 'M_G2',
            shader: 'Matcap',
            texture: '/textures/matcaps/M_G2.jpg'
        },
        {
            name: 'M_G3',
            shader: 'Matcap',
            texture: '/textures/matcaps/M_G3.jpg'
        },
        {
            name: 'M_BR1',
            shader: 'Matcap',
            texture: '/textures/matcaps/M_BR1.jpg'
        },
        {
            name: 'M_BR2',
            shader: 'Matcap',
            texture: '/textures/matcaps/M_BR2.jpg'
        }, ,
        {
            name: 'M_Y1',
            shader: 'Matcap',
            texture: '/textures/matcaps/Yellow1.png'
        },
        {
            name: 'Glass',
            shader: 'Matcap',
            texture: '/textures/matcaps/2.png'
        },
        {
            name: 'StopperRedLight',
            shader: 'Unlit',
        },
        {
            name: 'StopperGreenLight',
            shader: 'Unlit',
        },
        {
            name: 'Matcap_Brown2',
            shader: 'Matcap',
            texture: '/textures/matcaps/Brown2.png'
        },
        {
            name: 'Matcap_Yellow1',
            shader: 'Matcap',
            texture: '/textures/matcaps/Yellow1.png'
        },
        {
            name: 'Matcap_Red1',
            shader: 'Matcap',
            texture: '/textures/matcaps/Red1.png'
        },
        {
            name: 'Matcap_Red2',
            shader: 'Matcap',
            texture: '/textures/matcaps/Red2.png'
        },
        {
            name: 'Shadow',
            shader: 'Shadow'
        },
        {
            name: 'Outline',
            shader: 'Outline'
        },
        {
            name: 'TugSmoke',
            shader: 'TugSmoke'
        },
        {
            name: 'M_CandyHelperEye',
            shader: 'Unlit',
            color: '#ffffff'
        },
        {
            name: 'sleepz',
            shader: 'Sleepz'
        },
        {
            name: 'PhotoshopBucket',
            shader: 'TugSmoke',
            color: '#12d480'
        },
        {
            name: 'M_Plank',
            shader: 'Unlit',
            color: '#878256'
        },
        {
            name: 'M_Rail',
            shader: 'Unlit',
            color: '#d2dee0'
        },
        {
            name: 'PhotoshopIcon1',
            shader: 'AssetDefault',
            color: '#1269d4'
        },
        {
            name: 'PhotoshopIcon2',
            shader: 'AssetDefault',
            color: '#c7dce7'
        },
        {
            name: 'maxicon1',
            shader: 'AssetDefault',
            color: '#145f71'
        },
        {
            name: 'maxicon2',
            shader: 'AssetDefault',
            color: '#a2bbbb'
        },
        {
            name: 'maxpot1',
            shader: 'AssetDefault',
            color: '#3d7799'
        },
        {
            name: 'maxpot2',
            shader: 'AssetDefault',
            color: '#2d4958'
        },
        {
            name: 'maxknot',
            shader: 'AssetDefault',
            color: '#e7e3c7'
        },
        {
            name: 'maxcylinder',
            shader: 'AssetDefault',
            color: '#59b8e8'
        },
        {
            name: 'maxhedra',
            shader: 'AssetDefault',
            color: '#9a6def'
        },
        {
            name: 'zbrushicon1',
            shader: 'AssetDefault',
            color: '#0c0c0c'
        },
        {
            name: 'zbrushicon2',
            shader: 'AssetDefault',
            color: '#9dbabf'
        },
        {
            name: 'zbrushicon3',
            shader: 'Unlit',
            texture: '/textures/zbrushicon.jpg'
        },
        {
            name: 'substanceicon1',
            shader: 'AssetDefault',
            color: '#0c0c0c'
        },
        {
            name: 'substanceicon2',
            shader: 'AssetDefault',
            color: '#9dbabf'
        },
        {
            name: 'substanceicon3',
            shader: 'Unlit',
            texture: '/textures/substanceicon.jpg'
        },
        {
            name: 'sb12',
            shader: 'Unlit',
            texture: '/textures/substanceicon.jpg'
        },
        {
            name: 'sb13',
            shader: 'Unlit',
            texture: '/textures/zbrushicon.jpg'
        },
        {
            name: 'kusimprojection',
            shader: 'Matcap',
            texture: '/textures/matcaps/2.png'
        },
        {
            name: 'kusimseamat',
            shader: 'KusimSea',
            normal: '/textures/T_SEA_N.jpeg'
        },
        {
            name: 'kusimmask',
            shader: 'KusimMask',
            texture: '/textures/matcaps/2.png'
        },
        {
            name: 'kusimbake',
            shader: 'Unlit',
            texture: '/textures/kusim.jpg'
        },
        {
            name: 'M_kusimchart',
            shader: 'Unlit',
            texture: '/textures/T_Chart.jpg'
        },
        {
            name: 'UnrealIcon',
            shader: 'Unlit',
            texture: '/textures/UnrealIcon.png'
        },
        {
            name: 'UnrealBlueprintIcon',
            shader: 'Unlit',
            texture: '/textures/T_BlueprintIcon.jpg'
        },
        {
            name: 'arcelikproduct',
            shader: 'ArcelikProduct'
        },
        {
            name: 'UnigineIcon',
            shader: 'Unlit',
            texture: '/textures/T_Unigine.jpg'
        },
        {
            name: 'UnityIcon2',
            shader: 'Unlit',
            texture: '/textures/T_Unity.jpg'
        },
        {
            name: 'simtug',
            shader: 'Unlit',
            texture: '/textures/T_TUGM1A50.jpg'
        },
        {
            name: 'simbaggege',
            shader: 'Unlit',
            texture: '/textures/T_Baggege.jpg'
        },
        {
            name: 'tmx200',
            shader: 'Unlit',
            texture: '/textures/T_TMX200S.jpeg'
        },
        {
            name: 'FIM92_1',
            shader: 'AssetDefault',
            color: '#021006'
        },
        {
            name: 'FIM92_2',
            shader: 'AssetDefault',
            color: '#021a0b'
        },
        {
            name: 'FIM92_3',
            shader: 'AssetDefault',
            color: '#c45d19'
        },
        {
            name: 'baloon1',
            shader: 'AssetDefault',
            color: '#acacac'
        },
        {
            name: 'baloon2',
            shader: 'AssetDefault',
            color: '#af3bc4'
        },
        {
            name: 'baloon3',
            shader: 'AssetDefault',
            color: '#274c93'
        },
        {
            name: 'baloon4',
            shader: 'AssetDefault',
            color: '#8723dc'
        },
        {
            name: 'baloon5',
            shader: 'AssetDefault',
            color: '#dcb423'
        },
        {
            name: 'baloon6',
            shader: 'AssetDefault',
            color: '#dc6d23'
        },
        {
            name: 'baloon7',
            shader: 'AssetDefault',
            color: '#dc6d23'
        },
        {
            name: 'BaloonFreelence',
            shader: 'Unlit',
            texture: '/textures/T_FreelanceGameDev.png',
            transparent: true
        },
        {
            name: 'IconSheet',
            shader: 'Unlit',
            texture: '/textures/T_IconSheet_H.jpg'
        },
        {
            name: 'levelprop',
            shader: 'Unlit',
            texture: '/textures/T_Levelprops.jpg'
        }
    ]

    Database = {
        Undefined: undefined
    }

    world

    LoadMaterials() {
        this.materialsToLoad.forEach(mat => {
            if (mat.shader === 'Unlit') {
                let texture = null;
                if (mat.texture !== undefined) {
                    texture = this.world.loadTexture(mat.texture);
                    texture.flipY = false;
                }
                let color = 0xffffff
                if (mat.color !== undefined)
                    color = mat.color;


                this.Database[mat.name] = new MeshBasicMaterial({
                    name: mat.name,
                    color: color,
                    map: texture
                });
                if (mat.transparent !== undefined)
                    this.Database[mat.name].transparent = mat.transparent;

            } else if (mat.shader === 'Matcap') {
                let texture = this.world.loadTexture(mat.texture);
                this.Database[mat.name] = new MeshMatcapMaterial({name: mat.name, matcap: texture});
            } else if (mat.shader === 'GroundGrass') {


                let uniforms = {};
                if (mat.texture !== undefined) {
                    let texture = this.world.loadTexture(mat.texture);
                    texture.flipY = false;
                    uniforms.texture1 = {value: texture};
                }

                let shadowtexture = null;
                if (mat.shadowTexture !== undefined) {
                    shadowtexture = this.world.loadTexture(mat.shadowTexture);
                    shadowtexture.flipY = false;
                }
                uniforms.shadowtexture = {value: shadowtexture};
                uniforms._BaseColor = {value: new Color('#a8835d')}
                uniforms._GridColor = {value: new Color('#92b13e')}
                uniforms.s1 = {value: 0.55}
                uniforms.s2 = {value: 1}

                //this.world.dgui.add(uniforms.s1,'value',0,1).name('S1')
                //this.world.dgui.add(uniforms.s2,'value',0,1).name('S2')


                this.Database[mat.name] = new ShaderMaterial(
                    {
                        uniforms: uniforms,
                        vertexShader: GroundVertexShader,
                        fragmentShader: GroundFragmentShader
                    }
                );
            } else if (mat.shader === 'AssetDefault') {

                let uniforms = {};
                uniforms._BaseColor = {value: new Color('#1776ea')}
                if (mat.color !== undefined) {
                    uniforms._BaseColor.value = new Color(mat.color)
                }
                this.Database[mat.name] = new ShaderMaterial(
                    {
                        uniforms: uniforms,
                        vertexShader: AssetDefaultVertex,
                        fragmentShader: AssetDefaultFragment
                    }
                );
            } else if (mat.shader === 'Shadow') {

                let uniforms = {};
                this.Database[mat.name] = new ShaderMaterial(
                    {
                        vertexShader: ShadowVertex,
                        fragmentShader: ShadowFragment
                    }
                );
                this.Database[mat.name].transparent = true
                this.Database[mat.name].depthWrite = false
                //this.Database[mat.name].sizeAttenuation=true

            } else if (mat.shader === 'Outline') {

                let uniforms = {};
                this.Database[mat.name] = new ShaderMaterial(
                    {
                        vertexShader: OutlineVertex,
                        fragmentShader: OutlineFragment
                    }
                );
            } else if (mat.shader === 'TugSmoke') {

                let uniforms = {};
                uniforms._BaseColor = {value: new Color('#acacac')}
                if (mat.color !== undefined) {
                    uniforms._BaseColor.value = new Color(mat.color)
                }
                this.Database[mat.name] = new ShaderMaterial(
                    {
                        uniforms: uniforms,
                        vertexShader: TugSmokeVertex,
                        fragmentShader: TugSmokeFragment
                    }
                );
            } else if (mat.shader === 'Sleepz') {
                this.Database[mat.name] = new ShaderMaterial(
                    {
                        vertexShader: SleepzVertex,
                        fragmentShader: SleepzFragment
                    }
                );
            } else if (mat.shader === 'KusimMask') {

                this.Database[mat.name] = new ShaderMaterial(
                    {

                        vertexShader: KusimMaskVertex,
                        fragmentShader: KusimMaskFragment
                    }
                );
                this.Database[mat.name].transparent = true
            } else if (mat.shader === 'KusimSea') {

                let normalmap = this.world.loadTexture(mat.normal);

                normalmap.wrapS = normalmap.wrapT = RepeatWrapping
                let uniforms = {};
                uniforms._NormalMap = {value: normalmap}
                uniforms.u_time = {value: 0}
                this.Database[mat.name] = new ShaderMaterial(
                    {
                        uniforms: uniforms,
                        vertexShader: KusimSeaVertex,
                        fragmentShader: KusimSeaFragment
                    }
                );
                this.Database[mat.name].transparent = true
                this.MaterialsToUpdateTime.push(this.Database[mat.name])
            } else if (mat.shader === 'GroundSea') {
                let uniforms = {};
                uniforms._BaseColor = {value: new Color('#3790d5')}
                if (mat.color !== undefined) {
                    uniforms._BaseColor.value = new Color(mat.color)
                }


                let screensize = {
                    x: this.levelmanager.entitiy.world.ScreenSize.width * this.levelmanager.world.ScreenSize.PixelRatio,
                    y: this.levelmanager.entitiy.world.ScreenSize.height * this.levelmanager.world.ScreenSize.PixelRatio
                }

                uniforms.screenSize = {value: screensize}
                uniforms.campos = {value: new Vector3(0, 0, 0)}
                uniforms.camdir = {value: new Vector3(0, 0, 0)}
                uniforms.u_time = {value: 0}

                let supportsDepthTextureExtension = !!this.levelmanager.world.renderer.extensions.get(
                    "WEBGL_depth_texture"
                );
                supportsDepthTextureExtension = false;
                //uniforms.tDepth = {value: this.levelmanager.depthRenderTarget.depthTexture}
                uniforms.tDepth = {value:supportsDepthTextureExtension === true ? this.levelmanager.depthRenderTarget.depthTexture : this.levelmanager.depthRenderTarget.texture}

                this.Database[mat.name] = new ShaderMaterial(
                    {
                        defines: {
                            DEPTH_PACKING: supportsDepthTextureExtension === true ? 0 : 1
                        },
                        uniforms: uniforms,
                        vertexShader: GroundSeaVertexShader,
                        fragmentShader: GroundSeaFragmentShader,
                    }
                );
                this.MaterialsToUpdateCamPos.push(this.Database[mat.name])
                this.MaterialsToUpdateCamDir.push(this.Database[mat.name])
                this.MaterialsToUpdateTime.push(this.Database[mat.name])
            } else if (mat.shader === 'ArcelikProduct') {
                let uniforms = {};
                uniforms._BaseColor = {value: new Color('#323232')}
                uniforms._PaintColor = {value: new Color('#797979')}
                uniforms._PaintX = {value: 0}
                this.Database[mat.name] = new ShaderMaterial(
                    {
                        uniforms: uniforms,
                        vertexShader: ArcelikProductVertex,
                        fragmentShader: ArcelikProductFragment
                    }
                );
            } else if (mat.shader === 'GroundText') {
                let uniforms = {};
                uniforms._BaseColor = {value: new Color('#ffffff')}
                if (mat.color !== undefined) {
                    uniforms._BaseColor.value = new Color(mat.color)
                }
                this.Database[mat.name] = new ShaderMaterial(
                    {
                        uniforms: uniforms,
                        vertexShader: GroundTextVertex,
                        fragmentShader: GroundTextFragment
                    }
                );
            }
        })
    }

    Update(delta) {
        this.timer += delta
        if (this.timer >= 1000)
            this.timer = 0
        this.MaterialsToUpdateTime.forEach(m => {
            m.uniforms.u_time.value = this.timer;
        })


        let campos = new Vector3(0, 0, 0);
        this.levelmanager.camera.getWorldPosition(campos)
        this.MaterialsToUpdateCamPos.forEach(m => {
            m.uniforms.campos.value = campos;
        })
        let camdir = Utilities.GetDirVector(new Vector3(0, 0, 1), this.levelmanager.camera)

        this.MaterialsToUpdateCamPos.forEach(m => {

            m.uniforms.camdir.value = camdir;
        })
    }
}

