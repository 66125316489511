import * as THREE from 'three'
import SnakeCarrier from "./SnakeCarrier";
import SnakeJoint from "./SnakeJoint";
import Component from "../Engine/Core/Component";
import Utilities from "../Engine/Utilities";
import TrailPathPositioner from "../Trail/TrailPathPositioner";
import Rail from "./Rail";
import {Group, Matrix4, SphereGeometry, Vector2, Vector3} from "three";
import ShadowMaker from "./ShadowMaker";
import CPUParticleEmitter from "../Engine/Components/ParticleSystem/CPUParticleEmitter";
import {lerp} from "three/src/math/MathUtils";

export default class Tug extends Component {


    cubeMesh
    carriers = []
    scene
    joints = []
    moveVector = new THREE.Vector2();
    maxSpeed = 3.5
    movespeed = 0
    rotateSpeed = 1.5
    acc = 3
    world
    levelmanager
    pathcomputer
    railController
    railpositioner
    railPosition = 0;
    breaktimer = 0;

    smokeEmitterTimer = 0;
    tugAnimTimer = 0
    leftObj
    StopDelay = 0
    PathBlockPoint = 0;

    constructor(levelManager) {
        super()
    }

    Init() {

        this.world = this.entitiy.world;
        let tug = this;
        tug.cubeMesh = this.world.modelDatabase.database.TugMesh.scene.clone();
        tug.transform.add(tug.cubeMesh)
        tug.cubeMesh.position.y += 0.25;
    }

    Build(lm, railStartPos) {
        this.levelmanager = lm
        this.pathcomputer = this.levelmanager.pathcomputer;
        let currentLength = this.pathcomputer.GetPathLength();
        this.railPosition = currentLength - railStartPos;
        this.PathBlockPoint = this.pathcomputer.GetPathLength() - 20;

        this.railpositionerBack = Utilities.SpawnWithComponent("RailTest", TrailPathPositioner, this.world)
        this.railpositionerBack.updateRotation = false;
        this.railpositionerBack.pathcomputer = this.pathcomputer;

        this.railController = Utilities.SpawnWithComponent("RailTest", Rail, this.world)
        this.CreateRailModel()

        this.railpositioner = this.entitiy.AddComponent(new TrailPathPositioner());
        this.railpositioner.PathComputer = this.pathcomputer;
        this.railpositioner.lookback = true;

        this.ShadowMaker = Utilities.SpawnWithComponent("TugShadow", ShadowMaker, this.world);
        this.transform.add(this.ShadowMaker.transform);
        this.ShadowMaker.Build(2, 4.2)
        this.ShadowMaker.transform.position.z += 0.35

        this.tugsmoke = Utilities.SpawnWithComponent("TugSmokeMaker", CPUParticleEmitter, this.world);

        this.tugsmoke.transform.position.y = 1.35;
        this.tugsmoke.transform.position.z = 1.44;
        this.cubeMesh.add(this.tugsmoke.transform);
        this.tugsmoke.InitEmitter(new SphereGeometry(1, 12, 12), this.world.materialDatabase.Database.TugSmoke);
        this.tugsmoke.emitPeriod = 1;
        this.leftObj = new Group();
        this.transform.add(this.leftObj)
        this.leftObj.position.set(-1, 0, 0)


        let tug = this;
        let inverseRailPos = {
            Calculate: () => {
                inverseRailPos.pos = tug.pathcomputer.GetPathLength() - tug.railPosition
            },
            pos: 0
        }
        if (this.entitiy.world.debug) {
            this.world.dgui.add(this, 'railPosition', 10, this.pathcomputer.GetPathLength()).listen()
            this.world.dgui.add(inverseRailPos, 'pos').name('Inverse Rail Pos').listen()
            this.world.dgui.add(inverseRailPos, 'Calculate').name('Calculate Inverse Pos')
        }
        this.railpositioner.PlaceOnPath(this.railPosition)
    }

    CreateRailModel() {
        let pointCount = this.pathcomputer.PathPoints.length;
        let points = this.pathcomputer.PathPoints;


        for (let i = 1; i < pointCount; i++) {
            let rotationMatrix = new Matrix4().extractRotation(points[pointCount - i].transform.matrixWorld);
            let leftVector = new Vector3(-1, 0, 0).applyMatrix4(rotationMatrix).normalize();
            let forwardVector = new Vector3(0, 0, 1).applyMatrix4(rotationMatrix).normalize();
            this.railController.AddRail(points[pointCount - i].transform.position, forwardVector, leftVector)
        }


        this.pathcomputer.PathPoints.forEach(v3 => {

            //this.railController.AddRail(v3)
        })
    }

    BindControls(document) {
        return 0;
        let tug = this;
        document.onkeydown = function (e) {
            if (e.keyCode === 37) {
                tug.moveVector.x = 1
            }
            if (e.keyCode === 38) {
                tug.moveVector.y = 1
            }
            if (e.keyCode === 39) {
                tug.moveVector.x = -1
            }
            if (e.keyCode === 40) {
                tug.moveVector.y = -1
            }
            /*if (e.keyCode === 32)
                tug.CreateCarriers(1)*/
        }

        document.onkeyup = (e) => {
            if (e.keyCode === 37) {
                if (tug.moveVector.x === 1)
                    tug.moveVector.x = 0
                tug.moveVector.x = 0
            }
            if (e.keyCode === 38) {
                tug.moveVector.y = 0
            }
            if (e.keyCode === 39) {
                if (tug.moveVector.x === -1)
                    tug.moveVector.x = 0
            }
            if (e.keyCode === 40) {
                tug.moveVector.y = 0
            }
        }
        document.getElementById("ForwardButton1").addEventListener('mousedown', () => {
            tug.moveVector.y = 1
        })
        document.getElementById("ForwardButton1").addEventListener('mouseup', () => {
            tug.moveVector.y = 0
        })
        document.getElementById("BackwardButton1").addEventListener('mousedown', () => {
            tug.moveVector.y = -1
        })
        document.getElementById("BackwardButton1").addEventListener('mouseup', () => {
            tug.moveVector.y = 0
        })

        //Touch
        document.getElementById("ForwardButton1").addEventListener('touchstart', () => {
            tug.moveVector.y = 1
        })
        document.getElementById("ForwardButton1").addEventListener('touchend', () => {
            tug.moveVector.y = 0
        })
        document.getElementById("BackwardButton1").addEventListener('touchstart', () => {
            tug.moveVector.y = -1
        })
        document.getElementById("BackwardButton1").addEventListener('touchend', () => {
            tug.moveVector.y = 0
        })
    }

    SetStopDelay(delayAmount) {
        this.StopDelay = delayAmount;
    }

    Update(delta) {

        if (this.cubeMesh === undefined)
            return;

        this.StopDelay -= delta;

        if (this.levelmanager.controller !== undefined) {
            let forward = Utilities.GetDirVector(new Vector3(0, 0, -1), this.transform);
            let forwardv2 = new Vector2(forward.x, forward.z).normalize();
            let left = Utilities.GetDirVector(new Vector3(1, 0, 0), this.transform);
            let leftv2 = new Vector2(left.x, left.z).normalize();

            let jInput = this.levelmanager.controller.joysticInput;
            let dotY = forwardv2.dot(jInput);
            if (isNaN(dotY))
                dotY = 0;
            let dotX = leftv2.dot(jInput);
            if (isNaN(dotX))
                dotX = 0;
            this.moveVector.y = dotY;

            if (dotY > -0.75)
                this.moveVector.x = -dotX;
            else
                this.moveVector.x = 0;

        }

        if (Math.abs(this.moveVector.y) > 0)
            this.smokeEmitterTimer += delta * 2
        else
            this.smokeEmitterTimer -= delta * 0.25
        this.smokeEmitterTimer = Math.min(this.smokeEmitterTimer, 1);
        this.smokeEmitterTimer = Math.max(this.smokeEmitterTimer, 0);


        this.tugsmoke.emitPeriod = lerp(0.75, 0.025, this.smokeEmitterTimer)
        this.tugsmoke.Speedrange = new Vector2(this.smokeEmitterTimer + 0.5, this.smokeEmitterTimer * 2 + 1)

        this.tugAnimTimer += delta * (this.smokeEmitterTimer) * 16;
        let scale = (Math.sin(this.tugAnimTimer) + 2) * 0.05 + 1;

        this.cubeMesh.scale.set(1, scale, 1);

        if (this.StopDelay > 0) {
            this.moveVector.x = 0;
            this.moveVector.y = 0;
        }
        this.UpdateTug(delta)
        this.UpdatePathSystem();
        this.UpdateCarriers();
    }

    UpdateCarriers(delta) {

        for (let i = 0; i < this.carriers.length; i++) {
            this.carriers[i].positioner.PlaceOnPath(i * 1.25 + 1.125 + this.railPosition);
        }

    }

    UpdatePathSystem() {

        if (this.railPosition > 0)
            return;
        if (this.pathcomputer.PathPoints.length > 1) {

            this.pathcomputer.PathPoints[0].transform.position.copy(this.transform.position)
            this.pathcomputer.PathPoints[0].transform.rotation.copy(this.transform.rotation)


            let lastPointDistance = this.pathcomputer.PathPoints[1].transform.position.distanceTo(this.transform.position)
            this.pathcomputer.PathPoints[0].pointLength = lastPointDistance;


            if (lastPointDistance < 0.1)
                return;

        }

        let leftVec = new Vector3(0, 0, 0)
        this.leftObj.getWorldPosition(leftVec)
        leftVec = leftVec.sub(this.transform.position);
        leftVec.y = 0;
        leftVec = leftVec.normalize()
        let forwardVec = new Vector3(0, 0, 0);
        this.transform.getWorldDirection(forwardVec)
        this.pathcomputer.AddPathPointToStart(this.transform.position, this.transform.rotation)
        this.railController.AddRail(this.pathcomputer.PathPoints[0].transform.position, forwardVec, leftVec);
        let lastPointDistance = this.pathcomputer.PathPoints[0].transform.position.distanceTo(this.transform.position)
        this.pathcomputer.PathPoints[0].pointLength = lastPointDistance;

    }

    ComputeMoveSpeed(delta) {

        if (this.moveVector.y === 0) {

            let k = this.movespeed > 0 ? 1 : -1
            this.movespeed += -delta * k * 4
            this.breaktimer = 0;


        } else if (this.moveVector.y > 0) {
            this.movespeed += delta * this.acc * 5 * Math.abs(this.moveVector.y)
            this.breaktimer = 0;
        } else if (this.moveVector.y < 0) {
            if (this.movespeed > 0)
                this.movespeed -= delta * this.acc * 2 * Math.abs(this.moveVector.y)
            else
                this.breaktimer += delta;
        }

        if (this.movespeed > this.maxSpeed)
            this.movespeed = this.maxSpeed;
        else if (this.movespeed < -this.maxSpeed)
            this.movespeed = -this.maxSpeed;


    }

    UpdateTug(delta) {

        if (this.cubeMesh === undefined)
            return
        this.ComputeMoveSpeed(delta);
        if (this.railPosition > 0) {

            if (this.breaktimer > 0.25)
                this.movespeed -= delta * this.acc * 2
            this.railPosition -= this.movespeed * delta;

            if (this.railPosition <= 0) {
                this.railPosition = 0;
                return;
            }
            if (this.railPosition >= this.PathBlockPoint)
                this.railPosition = this.PathBlockPoint;
            this.railpositioner.PlaceOnPath(this.railPosition)
            return;
        }

        let forwardSpeedPercent = this.movespeed / this.maxSpeed;

        if (this.breaktimer > 0.25)
            this.railPosition = delta;

        this.transform.translateZ(this.movespeed * delta)
        this.transform.rotateY(delta * this.moveVector.x * this.rotateSpeed * forwardSpeedPercent)
        //this.UpdateJoints();
        //this.BaitCollisionControl()
    }

    GetCarrier(carrierIndex) {
        return this.carriers[carrierIndex]
    }

    GetLastCarrier() {
        return this.carriers[this.carriers.length - 1]
    }

    BaitCollisionControl() {
        if (this.levelmanager.baitSpawner === undefined)
            return
        let consumedBait;
        for (let i = 0; i < this.levelmanager.baitSpawner.baits.length; i++) {
            let targetBait = this.levelmanager.baitSpawner.baits[i];
            let dist = 0.0
            dist = targetBait.transform.position.distanceToSquared(this.transform.position);

            if (dist < 6) {
                targetBait.consumeBait(this);
            }
        }
    }


    CreateCarriers(count, playspawnanim = true) {
        for (let i = 0; i < count; i++) {
            this.CreateCarrier();
            if (playspawnanim) {
                this.GetLastCarrier().PlaySpawnAnim((i / count) * 0.4)
            }
        }


    }

    CreateCarrier() {

        let newcarrier = Utilities.SpawnWithComponent("Carrier", SnakeCarrier, this.entitiy.world);
        this.carriers.push(newcarrier)
        newcarrier.positioner.PathComputer = this.pathcomputer;

        let spawnPos = this.transform.position;
        if (this.carriers.length > 1) {
            let dir = new THREE.Vector3();
            this.carriers[this.carriers.length - 2].transform.getWorldDirection(dir)
            spawnPos = this.carriers[this.carriers.length - 2].transform.position;
            spawnPos.sub(dir)

        }
    }

    UpdateJoints() {
        for (let i = 0; i < this.joints.length; i++) {
            this.joints[i].UpdateTransform();
        }
    }
}
