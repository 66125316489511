import Component from "../Engine/Core/Component";
import Utilities from "../Engine/Utilities"; 
import LiftObject from "../Player/LiftObject"; 
import CandyHelper from "../CandyHelper/CandyHelper"; 
import {gsap} from "gsap";
import ShadowMaker from "../Player/ShadowMaker";

export default class ZbrushStation extends Component {


 
    iconstartpos = [31.2859,0.5,32.1536, 0, 0, -1.5, 1.49343, 1.49343, 1.49343]
    icontargetpos = [-0.25, 1, 0.3, -1, 0, 0,0.5, 0.5, 0.5]
    stationEnabled = false

    BuildStation() {

        let station = this;
        let zbrushIcon = Utilities.SpawnWithComponent("zbrushiconmodel", LiftObject, this.entitiy.world).Build(
            'zbrushicon', this.iconstartpos, this.icontargetpos
        );

        let newHelper = Utilities.SpawnWithComponent("Candy_Helper_1", CandyHelper, this.entitiy.world);
        newHelper.transform.position.set(this.iconstartpos[0] + 1, this.iconstartpos[1] - 0.5, this.iconstartpos[2] - 0.5)
        newHelper.transform.rotation.y += Math.PI
        let shadow1= Utilities.SpawnWithComponent("Shadow",ShadowMaker,this.entitiy.world);
        shadow1.Build(2,2)
        shadow1.transform.position.copy(newHelper.transform.position)
        shadow1.transform.position.y+=0.5;
        shadow1.transform.position.z-=0.25;
        let shadow2= Utilities.SpawnWithComponent("Shadow",ShadowMaker,this.entitiy.world);
        shadow2.Build(2,1)
        shadow2.transform.position.copy(newHelper.transform.position)
        shadow2.transform.position.y+=0.75;
        //shadow2.transform.position.z-=.5;
        shadow2.transform.position.x-=0.5;
        shadow2.transform.rotateZ(-1.5)
        shadow2.transform.rotateX(-.5)
        /*
        let carrier =station.levelmanager.tug.GetCarrier(1)
        carrier.transform.attach( zbrushIcon.model)
        zbrushIcon.MoveToTarget()*/
        
        
        let startpos = newHelper.transform.position.clone();


        newHelper.CreateDelayTask(3, () => {
        });
        newHelper.CreateDelayTask(0.1, () => {
            newHelper.StartSleepAnim()
        });
        newHelper.CreateConditionWaitTask(station, 'stationEnabled');

        
        //LiftOff Target
        newHelper.CreateDelayTask(0.5, () => {
            newHelper.animationController.animation.PlayFade('liftoff', 0.25)
            newHelper.transform.attach(zbrushIcon.model)
            zbrushIcon.ReAssignMaterials();
            gsap.to(zbrushIcon.model.position, {x: 0, y: 1, z: 0})
            gsap.to(zbrushIcon.model.rotation, {x: 0, y: 0, z: 0})
            shadow1.entitiy.Destroy()
            shadow2.entitiy.Destroy()
        });
        //Move To Carrier 
        newHelper.CreateMoveToPointTask(() => {
            return station.levelmanager.tug.GetCarrier(1).transform.position
        }, 2 * 2, 3, () => {
            newHelper.StopSleepAnim()
            newHelper.animationController.animation.PlayFade('liftoffrun', 0.25)
        })
        //Throw to target
        newHelper.CreateDelayTask(0.4, () => {
            newHelper.animationController.animation.PlayFade('throw', 0.25)
        });
        newHelper.CreateDelayTask(0.5, () => {
            let carrier = station.levelmanager.tug.GetCarrier(1)
            carrier.transform.attach(zbrushIcon.model)
            zbrushIcon.MoveToTarget()
            newHelper.animationController.animation.PlayFade('throw', 0.25)
        });

        //Return To sleepPos

        newHelper.CreateMoveToPointTask(() => {
            return startpos
        }, 0.5, -1, () => {
            newHelper.movespeed*=0.15
            newHelper.animationController.animation.PlayFade('walk', 0.25)
        }) 
        let looper={condition:false}
        newHelper.CreateConditionWaitTask(looper, 'condition',()=>
        {
            newHelper.StartSleepAnim()
        });

        //newHelper.CreateDestroyCandyTask();

    }

    StartStationAnim() {
        if (this.stationEnabled)
            return; 
        this.stationEnabled = true;
    }
}