import Component from "../Engine/Core/Component";
import {gsap, Linear} from "gsap"
import Utilities from "../Engine/Utilities";
import {Vector3} from "three";
import TrailPathPositioner from "../Trail/TrailPathPositioner";
import TrailPathComputer from "../Trail/TrailPathComputer";
import CandyHelper from "../CandyHelper/CandyHelper";
import LiftObject from "../Player/LiftObject";
import TrailTrigger from "../Trail/TrailTrigger";
import informationPoint from "../Information/InformationPoint";

export default class SimulationStation extends Component {

    levelmanager
    arcelikBrain = {
        Update: (delta) => {
        },
        splinecomputer: {},
        products: [],
        pathlength: 0,
        timer: 0
    }

    modelPoses = {
        UnigineIcon: [-53.5235, 0.0, 16.3235, 0, 0, 0, 1.0, 1.0, 1.0],
        snowpiercer: [-44.2339, 0.0, 13.4253, 0, 0, 0, 1.0, 1.0, 1.0],
        fire: [-36.7142, 0.0, 13.4253, 0, 0, 0, 1.0, 1.0, 1.0],
        Arcelik: [-50.529, 0.0, 6.59746, 0, 0, 0, 1.0, 1.0, 1.0],
        UnityIcon: [-52.2207, 0.0, 2.27755, 0, 3.1415, 0, 1.0, 1.0, 1.0],
        Truck: [-47.4048, 0.0, 25.9269, 0, 0, 0, 1.0, 1.0, 1.0],
        PhaetonGround: [-53.8084, 0.0, 22.0645, 0, 0, 0, 1.0, 1.0, 1.0],
 
        FIM92:[-61.5304,0.0,-19.4356,0,0,0,1.0,1.0,1.0],
        tmx:[-59.1259,0.0,-11.1889,0,0,0,1.0,1.0,1.0],
        simtug:[-51.5833,0.0,-14.0583,0,0,0,1.0,1.0,1.0],

    }

    BuildStation() {

        this.StartTruckModel()
        this.StartPhaetonModel()
        this.StartSnowPiercerModel()
        this.StartFireSimModel()
        this.StartArcelikModel()
        this.StartUnigineIconModel()
        this.StartUnityIconModel()
        this.StartSimTugModel()
        this.StartSimTmx200Model()
        this.StartFim92Model()
    }

    StartUnityIconModel() {
        let lm = this.levelmanager;
        let unityIcon = Utilities.SpawnWithComponent("unityIcon", LiftObject, this.entitiy.world).Build(
            'unityicon', this.modelPoses.UnityIcon, [0.25, 1, 0.5, -1, 0, 0, 0.5, 0.5, 0.5], false
        );
        let unityIconLiftTrigger = Utilities.SpawnWithComponent("UnigineLiftTrigger", TrailTrigger, lm.world);
        unityIconLiftTrigger.triggerTargetPositioner = lm.tug.railpositioner;
        unityIconLiftTrigger.triggerPos = lm.pathcomputer.GetPathLength() - lm.TriggerPositions.UnityIconLiftPos.t;
        unityIconLiftTrigger.triggerLength = lm.TriggerPositions.UnityIconLiftPos.l;
        unityIconLiftTrigger.OnTriggerStart = () => {

            if (!unityIconLiftTrigger.alreadyTriggered) {
                let carrier = lm.tug.GetCarrier(2)
                carrier.transform.attach(unityIcon.model)
                unityIcon.MoveToTarget();
            }
        }
    }

    StartFim92Model() {
        let FIM092Model = this.levelmanager.modelDatabase.database.FIM92.scene.clone();
        this.transform.add(FIM092Model)
        Utilities.DataToTransformApply(FIM092Model,
            this.modelPoses.FIM92
        )
        this.levelmanager.CreateInfoPointOnLine(
            'FIM92',
            'FIM92Panel',
            this.levelmanager.TriggerPositions.fim92InfoPos.t,
            this.levelmanager.TriggerPositions.fim92InfoPos.l,
            {x: FIM092Model.position.x, y: FIM092Model.position.y + 4, z: FIM092Model.position.z}
        )
    }

    StartSimTmx200Model() {
        let simtmx200model = this.levelmanager.modelDatabase.database.simtmx200.scene.clone();
        this.transform.add(simtmx200model)
        Utilities.DataToTransformApply(simtmx200model,
            this.modelPoses.tmx
        )
        this.levelmanager.CreateInfoPointOnLine(
            'Pushback',
            'PushbackPanel',
            this.levelmanager.TriggerPositions.pushbackInfoPos.t,
            this.levelmanager.TriggerPositions.pushbackInfoPos.l,
            {x: simtmx200model.position.x+2, y: simtmx200model.position.y + 3, z: simtmx200model.position.z-1}
        )
    }

    StartSimTugModel() {

        let simtugmodel = this.levelmanager.modelDatabase.database.simtug.scene.clone();
        this.transform.add(simtugmodel)
        Utilities.DataToTransformApply(simtugmodel,
            this.modelPoses.simtug
        )
        this.levelmanager.CreateInfoPointOnLine(
            'Baggage',
            'BaggagePanel',
            this.levelmanager.TriggerPositions.bagaggeInfoPos.t,
            this.levelmanager.TriggerPositions.bagaggeInfoPos.l,
            {x: simtugmodel.position.x-2, y: simtugmodel.position.y + 3, z: simtugmodel.position.z-1}
        )
    }

    StartUnigineIconModel() {

        let lm = this.levelmanager;
        let UnigineIcon = Utilities.SpawnWithComponent("UnigineIconModel", LiftObject, this.entitiy.world).Build(
            'UnigineIcon', this.modelPoses.UnigineIcon, [0.25, 1, 0.3, -1, 0, 0, 0.5, 0.5, 0.5], false
        );
        let UnigineLiftTrigger = Utilities.SpawnWithComponent("UnigineLiftTrigger", TrailTrigger, lm.world);
        UnigineLiftTrigger.triggerTargetPositioner = lm.tug.railpositioner;
        UnigineLiftTrigger.triggerPos = lm.pathcomputer.GetPathLength() - lm.TriggerPositions.UnigineIconLiftPos.t;
        UnigineLiftTrigger.triggerLength = lm.TriggerPositions.UnityIconLiftPos.l;
        UnigineLiftTrigger.OnTriggerStart = () => {

            if (!UnigineLiftTrigger.alreadyTriggered) {
                let carrier = lm.tug.GetCarrier(2)
                carrier.transform.attach(UnigineIcon.model)
                UnigineIcon.MoveToTarget();
            }
        }
    }

    StartArcelikModel() {

        let ArcelikModel = this.levelmanager.modelDatabase.database.ArcelikSim.scene.clone();
        this.transform.add(ArcelikModel)
        Utilities.DataToTransformApply(ArcelikModel,
            this.modelPoses.Arcelik
        )
        let pathPoints = [-1.76526, 0.746427, 3.8222, -1.76526, 0.746427, 2.18371, -1.75095, 0.746427, 2.05975, -1.71022, 0.746427, 1.9459, -1.64632, 0.746427, 1.84544, -1.5625, 0.746427, 1.76163, -1.46205, 0.746427, 1.69773, -1.3482, 0.746427, 1.65699, -1.22424, 0.746427, 1.64269, -1.1075, 0.732025, 1.64269, -0.990758, 0.694882, 1.64269, -0.874018, 0.644095, 1.64269, -0.757278, 0.58876, 1.64269, -0.640538, 0.537973, 1.64269, -0.523798, 0.50083, 1.64269, -0.407058, 0.486427, 1.64269, 0.41012, 0.486427, 1.64269, 0.52686, 0.50083, 1.64269, 0.6436, 0.537973, 1.64269, 0.76034, 0.58876, 1.64269, 0.877079, 0.644095, 1.64269, 0.993819, 0.694882, 1.64269, 1.11056, 0.732025, 1.64269, 1.2273, 0.746427, 1.64269, 1.35126, 0.746427, 1.65699, 1.46511, 0.746427, 1.69773, 1.56557, 0.746427, 1.76163, 1.64938, 0.746427, 1.84544, 1.71328, 0.746427, 1.9459, 1.75402, 0.746427, 2.05975, 1.76832, 0.746427, 2.18371, 1.76832, 0.746427, 3.814]
        let wpos = new Vector3(0, 0, 0)
        ArcelikModel.getWorldPosition(wpos);
        Utilities.AddOffsetToPathPoints(pathPoints, wpos.x, wpos.y, wpos.z)
        this.arcelikBrain.splinecomputer = Utilities.SpawnWithComponent("ArcelikSpline", TrailPathComputer, this.entitiy.world)
        this.arcelikBrain.splinecomputer.CreatePreDefinedPathPoints(pathPoints);
        this.arcelikBrain.pathlength = this.arcelikBrain.splinecomputer.GetPathLength();

        for (let i = 0; i < 25; i++) {
            let productModel = this.levelmanager.modelDatabase.database.ArcelikProduct.scene.clone();
            let product = Utilities.SpawnWithComponent("Arcelik_Product_" + i, TrailPathPositioner, this.entitiy.world);
            product.Build(this.arcelikBrain.splinecomputer);
            product.transform.add(productModel);
            product.transform.scale.multiplyScalar(1.5)
            this.arcelikBrain.products.push(product);
        }
        let brain = this.arcelikBrain;
        brain.Update = (delta) => {
            brain.timer += delta * 0.25;
            brain.products.forEach((e, i) => {
                let pos = brain.timer + i;
                pos %= brain.pathlength;

                e.PlaceOnPath(pos)
            })
        }

        let helper;
        let helperParent;
        ArcelikModel.traverse((E) => {
            if (E.name === 'helperPos') {
                helper = E;
                helperParent = helper.parent;
            }
        })

        let chelper = Utilities.SpawnWithComponent("Arcelik Helper", CandyHelper, this.entitiy.world);

        let cpos = new Vector3(0, 0, 0);

        helper.getWorldPosition(cpos);
        this.entitiy.world.materialDatabase.Database.arcelikproduct.uniforms._PaintX = {value: cpos.x}
        chelper.transform.position.copy(cpos);
        chelper.transform.scale.multiplyScalar(0.5)
        setTimeout(function () {
            chelper.animationController.animation.PlayFade('kusimecdis', 0.25)
        }, 2000)

        helper.position.z = -10

        this.levelmanager.CreateInfoPointOnLine(
            'Factory',
            'FactoryPanel',
            this.levelmanager.TriggerPositions.arcelikInfoPos.t,
            this.levelmanager.TriggerPositions.arcelikInfoPos.l,
            {x: ArcelikModel.position.x, y: ArcelikModel.position.y + 4, z: ArcelikModel.position.z}
        )
    }

    StartFireSimModel() {
        let FireSimModel = this.levelmanager.modelDatabase.database.FireSim.scene.clone();
        this.transform.add(FireSimModel)
        Utilities.DataToTransformApply(FireSimModel,
            this.modelPoses.fire
        )
        let rotator;
        FireSimModel.traverse((E) => {
            if (E.name === 'rotator') {
                rotator = E;
            }
        })
        this.BuildRotationAnim(rotator, .3, .1, 2, .05, 2.5, .015, 2.5)
        this.levelmanager.CreateInfoPointOnLine(
            'RFF',
            'RFFPanel',
            this.levelmanager.TriggerPositions.fireInfoPos.t,
            this.levelmanager.TriggerPositions.fireInfoPos.l,
            {x: FireSimModel.position.x, y: FireSimModel.position.y + 4, z: FireSimModel.position.z+3}
        )
    }

    StartSnowPiercerModel() {
        let SnowPiercerModel = this.levelmanager.modelDatabase.database.SnowPiercer.scene.clone();
        this.transform.add(SnowPiercerModel)
        Utilities.DataToTransformApply(SnowPiercerModel,
            this.modelPoses.snowpiercer
        )
        let rotator;
        SnowPiercerModel.traverse((E) => {
            if (E.name === 'rotator') {
                rotator = E;
            }
        })
        this.BuildRotationAnim(rotator, .3, .1, 2, .05, 2.5, .015, 2.5)


        this.levelmanager.CreateInfoPointOnLine(
            'Snow',
            'SnowPiercerPanel',
            this.levelmanager.TriggerPositions.snowpiercerInfoPos.t,
            this.levelmanager.TriggerPositions.snowpiercerInfoPos.l,
            {x: SnowPiercerModel.position.x, y: SnowPiercerModel.position.y + 4, z: SnowPiercerModel.position.z+3}
        )
    }

    StartPhaetonModel() {
        let truckmodel = this.levelmanager.modelDatabase.database.phaeton.scene.clone();
        this.transform.add(truckmodel)
        Utilities.DataToTransformApply(truckmodel,
            this.modelPoses.PhaetonGround
        )
        let rotator1;
        let rotator2;
        truckmodel.traverse((E) => {
            if (E.name === 'rotator1') {
                rotator1 = E;
            }
            if (E.name === 'rotator2') {
                rotator2 = E;
            }
        })
        this.BuildRotationAnim(rotator1, .3, .1, 2, .05, 2.5, .015, 2.5)
        this.BuildRotationAnim(rotator2, 2.5, .1, 2, .05, 2.5, .015, 2.5)
        
        this.levelmanager.CreateInfoPointOnLine(
            'Phaeton',
            'PhaetonPanel',
            this.levelmanager.TriggerPositions.PhaetonInfoPos.t,
            this.levelmanager.TriggerPositions.PhaetonInfoPos.l,
            {x: truckmodel.position.x, y: truckmodel.position.y + 3, z: truckmodel.position.z}
        )
    }

    StartTruckModel() {
        let truckmodel = this.levelmanager.modelDatabase.database.TruckSim.scene.clone();
        this.transform.add(truckmodel)
        Utilities.DataToTransformApply(truckmodel,
            this.modelPoses.Truck
        )
        let rotator;
        truckmodel.traverse((E) => {
            if (E.name === 'rotator') {
                rotator = E;
            }
        })
        this.BuildRotationAnim(rotator, 0)

        this.levelmanager.CreateInfoPointOnLine(
            'Truck',
            'TruckInfoPanel',
            this.levelmanager.TriggerPositions.TruckInfoPos.t,
            this.levelmanager.TriggerPositions.TruckInfoPos.l,
            {x: truckmodel.position.x, y: truckmodel.position.y + 3, z: truckmodel.position.z}
        )

    }

    BuildRotationAnim(rotator, delay, x = .15, xdur = 1.156, y = .15, ydur = 2.5, z = .025, zdur = .5) {

        gsap.fromTo(
            rotator.rotation, {
                x: -x
            },
            {
                x: x,
                duration: xdur,
                yoyo: true,
                repeat: -1,
                delay: delay
            }
        )
        gsap.fromTo(
            rotator.rotation, {
                z: -z
            },
            {
                z: z,
                duration: zdur,
                yoyo: true,
                ease: Linear.easeNone,
                repeat: -1,
                delay: delay
            }
        )
        gsap.fromTo(
            rotator.rotation, {
                y: -y
            },
            {
                y: y,
                duration: ydur,
                yoyo: true,
                ease: Linear.easeNone,
                repeat: -1,
                delay: delay
            }
        )
    }

    Update(delta) {
        this.arcelikBrain.Update(delta);
    }

}