import Component from "../Engine/Core/Component";
import {Mesh, PlaneBufferGeometry} from "three";

export default class ShadowMaker extends Component
{
    
    Build(sizeX,sizeY)
    {
        let geo = new PlaneBufferGeometry(sizeX,sizeY,1,1);
        let mesh = new Mesh(geo,this.world.materialDatabase.Database.Shadow);
        mesh.rotateX(-1.57075)
        this.transform.add(mesh);
    }
    
    
}