export default class Component {
    entitiy
    transform

    constructor() {
    }

    Init() {
        this.transform = this.entitiy.transform;
    }

    RemoveComponent() {

    }

    Awake() {

    }

    Start() {

    }

    OnDestroy() {

    }

}