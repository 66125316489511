import Component from "../Engine/Core/Component";
import Utilities from "../Engine/Utilities";
import {gsap} from "gsap";
import {Mesh} from "three";

export default class LiftObject extends Component
{
    targetData = {
        v3:undefined, 
        rot:undefined,
        sl:undefined
    }
    model
    meshes = {
        mesh:[],
        materials:[]
    }
    Build(meshName,startTransformData,targetTransformData,makeGray=true)
    { 
        this.model = this.entitiy.world.modelDatabase.database[meshName].scene.children[0].clone();
        this.model.traverse(child=>{
            if (child instanceof Mesh)
            {
                this.meshes.mesh.push(child)
                this.meshes.materials.push(child.material)
                if(makeGray)
                    child.material=this.entitiy.world.materialDatabase.Database.M2; 
            }
        })
        this.transform.add(this.model) 
        Utilities.DataToTransformApply(this.model, startTransformData); 
        this.targetData=Utilities.DataToTransform(targetTransformData); 
        this.targetData.v3.x*=-1 
        return this;
    }
    ReAssignMaterials()
    {
        for( let i =0;i<this.meshes.mesh.length;i++)
        {
            this.meshes.mesh[i].material=this.meshes.materials[i]
        }
    }
    
    
    MoveToTarget()
    {

       /* this.model.position.copy(this.targetData.v3);
        this.model.rotation.copy(this.targetData.rot )*/
       
        gsap.to(this.model.position,{
            x:this.targetData.v3.x,
            y:this.targetData.v3.y,
            z:this.targetData.v3.z
        })
        gsap.to(this.model.rotation,{
            x:this.targetData.rot.x,
            y:this.targetData.rot.y,
            z:this.targetData.rot.z
        })
        gsap.to(this.model.scale,{
            x:this.targetData.sl.x,
            y:this.targetData.sl.y,
            z:this.targetData.sl.z
        }) 
    }
}