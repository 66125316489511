import Component from "../Engine/Core/Component";
import particleVertex from '../Shaders/PlusParticle/vertex.glsl'
import particleFragment from '../Shaders/PlusParticle/fragment.glsl'
import {
    AdditiveBlending,
    BufferGeometry,
    ShaderMaterial,
    Color,
    Float32BufferAttribute,
    DynamicDrawUsage,
    Points, Vector3, MathUtils
} from "three";
import Utilities from "../Engine/Utilities";

export default class PlusParticleSystem extends Component {
    levelmanager
    geometry
    particleSystem
    particlesCount = 100;

    followPositions = []
    escapeDirections = []
    timer = 0
    enabled = false;
    followTransform

    startCounter = 0;

    Start() {
        super.Start();
    }

    Build(startPositions = []) {

        this.particlesCount = startPositions.length / 3;

        let uniforms = {
            _JumpAmount: {value: 0.35}
        }
        const shaderMaterial = new ShaderMaterial({
            uniforms: uniforms,
            vertexShader: particleVertex,
            fragmentShader: particleFragment,
            vertexColors: true
        });
        this.geometry = new BufferGeometry();
        const positions = [];
        const colors = [];
        const sizes = [];
        const color = new Color();

        let startPoint = new Vector3(67.886, 0.0, 22.7911);
        for (let i = 0; i < this.particlesCount; i++) {

            this.followPositions.push((Math.random() * 2 - 1) * 0.5);
            this.followPositions.push(Math.random() + 1.5);
            this.followPositions.push((Math.random() * 2 - 1) * 0.5);

            this.escapeDirections.push(MathUtils.randFloat(-1, 1));
            this.escapeDirections.push(3);
            this.escapeDirections.push(MathUtils.randFloat(-1, 1));


        }
        for (let i = 0; i < startPositions.length; i++) {

            positions.push(startPositions[i]);
        }
        this.geometry.setAttribute('position', new Float32BufferAttribute(positions, 3).setUsage(DynamicDrawUsage));
        this.geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));
        this.geometry.setAttribute('size', new Float32BufferAttribute(sizes, 1).setUsage(DynamicDrawUsage));
        this.particleSystem = new Points(this.geometry, shaderMaterial);
        this.particleSystem.frustumCulled = false;
        this.transform.add(this.particleSystem)
        this.enabled = true;
        // this.followTransform=this.levelmanager.tug.transform;
        // this.CircleOnTarget(53,8)
    }


    Update(delta) {
        if (this.followTransform === undefined)
            return;
        this.timer += delta;
        const poses = this.geometry.attributes.position.array;
        this.startCounter += delta * 80;
        if (this.startCounter > this.particlesCount)
            this.startCounter = this.particlesCount;
        if (this.timer < 5) {

            for (let i = 0; i < this.startCounter * 3; i += 3) {
                poses[i] += this.escapeDirections[i]        *delta;
                poses[i + 1] += this.escapeDirections[i + 1]*delta;
                poses[i + 2] += this.escapeDirections[i + 2]*delta;
            }

            this.geometry.attributes.position.needsUpdate = true;
            return 0;
        }

        let basepos = this.followTransform.position;

        let maxSpeed = 3;
        let minSpeed = 1;
        for (let i = 0; i < this.startCounter * 3; i += 3) {
            let percent = i / this.particlesCount;
            let lerpSpeed = percent * (maxSpeed - minSpeed) + minSpeed;
            poses[i] += (Math.sin(this.timer + i)) * delta;
            poses[i + 2] += (Math.cos(this.timer + i)) * delta;
            poses[i] = Utilities.lerp(poses[i], basepos.x + this.followPositions[i], delta * lerpSpeed);
            poses[i + 1] = Utilities.lerp(poses[i + 1], basepos.y + this.followPositions[i + 1], delta * lerpSpeed);
            poses[i + 2] = Utilities.lerp(poses[i + 2], basepos.z + this.followPositions[i + 2], delta * lerpSpeed);
        }
        this.geometry.attributes.position.needsUpdate = true;
    }

    CircleOnTarget(radi, radirndRange) {
        return 0;
        let basePos = this.followTransform.position;
        const poses = this.geometry.attributes.position.array;
        for (let i = 0; i < poses.length; i += 3) {
            let percent = i / this.particlesCount;
            percent *= 3.1415;
            let rndradi = MathUtils.randFloat(radi - radirndRange, radi + radirndRange)

            poses[i + 0] = Math.sin(percent) * rndradi + basePos.x;
            poses[i + 2] = Math.cos(percent) * rndradi + basePos.z;
            poses[i + 1] = basePos.y + 2;
        }
        this.geometry.attributes.position.needsUpdate = true;

    }
}