import Component from "../Engine/Core/Component";
import {
    AxesHelper,
    BufferGeometry,
    DoubleSide, DynamicDrawUsage,
    Float32BufferAttribute, Mesh, MeshBasicMaterial,
    MeshMatcapMaterial,
    MeshPhongMaterial,
    Vector3
} from "three";
import Utilities from "../Engine/Utilities";
import {randFloat} from "three/src/math/MathUtils";

export default class Rail extends Component {

    leftRailGeometry
    RightRailGeometry
    lastPointPos
    maxPointCount = 16384
    plankgap = 0.25
    plankWidth = 0.35
    lastplacedPlankPoint
    railwidth = 0.05
    Awake() {
        super.Awake();

        let rail = this;
        this.leftRailGeometry = this.PrepareRailMesh();
        this.RightRailGeometry = this.PrepareRailMesh();
        //this.PlankGeometry=this.PreparePlankMesh();

    }

    PrepareRailMesh() {
        let mt = this.world.loadTexture("/textures/matcaps/M_G1.jpg", () => {
        })
        let geometry = new BufferGeometry();
        let indices = [];
        let normals = [];
        let vertices = []
        normals = []
        indices = []
        let vertCount = this.maxPointCount * 4 * 3;
        let indicesCount = this.maxPointCount * 4;
        for (let i = 0; i < vertCount; i++) {
            vertices.push(0)
        }
        for (let i = 4; i < indicesCount; i += 4) {
            indices.push(i - 4)
            indices.push(i - 3)            
            indices.push(i)

            indices.push(i - 3)
            indices.push(i + 1)
            indices.push(i)


            indices.push(i - 2)
            indices.push(i + 2)
            indices.push(i + 1)


            indices.push(i - 3)
            indices.push(i - 2)
            indices.push(i + 1)


            indices.push(i - 1)
            indices.push(i + 3)            
            indices.push(i + 2)

            indices.push(i - 2)
            indices.push(i - 1)
            indices.push(i + 2)
            


        }
        let normalCount = (this.maxPointCount * 4) / 4;
        for (let i = 0; i < normalCount; i++) {
            normals.push(-1)
            normals.push(0)
            normals.push(0)

            normals.push(-1)
            normals.push(1)
            normals.push(0)

            normals.push(1)
            normals.push(1)
            normals.push(0)

            normals.push(1)
            normals.push(0)
            normals.push(0)
        }
        geometry.setIndex(indices);
        this.verticiesAttribute = new Float32BufferAttribute(vertices, 3);
        geometry.setAttribute('position', this.verticiesAttribute);
        geometry.setAttribute('normal', new Float32BufferAttribute(normals, 3));
       
  
        const material = new MeshMatcapMaterial({          
            matcap: mt
        });
       // let mesh = new Mesh(geometry, this.entitiy.world.materialDatabase.Database.M_Rail);
        let mesh = new Mesh(geometry, material);
        mesh.frustumCulled = false;
        this.transform.add(mesh);
        return geometry;
    } 
    counter = 0
    plankCounter = 0

    AddRail(point, dir, left) {

        if (this.counter === 0)
            this.lastPointPos = point.clone();
 
        this.AddPlank(point, dir, left);
        let CurrentBaseVertIndex = this.counter * 12;
        let leftOffset = left.clone().multiplyScalar(.4)
        let rightOffset = left.clone().multiplyScalar(-.4)

        let vPos = point.clone();
        vPos.add(left.clone().multiplyScalar(-this.railwidth));

        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 0] = vPos.x + leftOffset.x;
        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 1] = vPos.y + leftOffset.y;
        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 2] = vPos.z + leftOffset.z;

        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 0] = vPos.x + rightOffset.x;
        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 1] = vPos.y + rightOffset.y;
        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 2] = vPos.z + rightOffset.z;

        vPos.set(point.x, point.y, point.z);
        vPos.add(left.clone().multiplyScalar(-this.railwidth)).y += 0.15;

        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 3] = vPos.x + leftOffset.x;
        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 4] = vPos.y + leftOffset.y;
        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 5] = vPos.z + leftOffset.z;

        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 3] = vPos.x + rightOffset.x;
        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 4] = vPos.y + rightOffset.y;
        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 5] = vPos.z + rightOffset.z;

        vPos.set(point.x, point.y, point.z);
        vPos.add(left.clone().multiplyScalar(this.railwidth)).y += 0.15;
        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 6] = vPos.x + leftOffset.x;
        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 7] = vPos.y + leftOffset.y;
        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 8] = vPos.z + leftOffset.z;

        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 6] = vPos.x + rightOffset.x;
        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 7] = vPos.y + rightOffset.y;
        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 8] = vPos.z + rightOffset.z;

        vPos.set(point.x, point.y, point.z);
        vPos.add(left.clone().multiplyScalar(this.railwidth));

        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 9] = vPos.x + leftOffset.x;
        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 10] = vPos.y + leftOffset.y;
        this.leftRailGeometry.attributes.position.array[CurrentBaseVertIndex + 11] = vPos.z + leftOffset.z;

        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 9] = vPos.x + rightOffset.x;
        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 10] = vPos.y + rightOffset.y;
        this.RightRailGeometry.attributes.position.array[CurrentBaseVertIndex + 11] = vPos.z + rightOffset.z;

        this.leftRailGeometry.attributes.position.needsUpdate = true;
        this.RightRailGeometry.attributes.position.needsUpdate = true;


        this.leftRailGeometry.setDrawRange(0, this.counter * 18);
        this.RightRailGeometry.setDrawRange(0, this.counter * 18);
        this.lastPointPos = point.clone();
        this.counter++
        if (this.counter >= this.maxPointCount) {
            this.leftRailGeometry.setDrawRange(0, this.counter * 18);
            this.RightRailGeometry.setDrawRange(0, this.counter * 18);
            this.counter = 0;
            this.leftRailGeometry = this.PrepareRailMesh();
            this.RightRailGeometry = this.PrepareRailMesh();
        }
    }

    AddPlank(point, dir, left) {
     
        if (this.lastplacedPlankPoint === undefined)
            this.lastplacedPlankPoint = point.clone()

        let len = ((point.clone().sub(this.lastplacedPlankPoint)).length());
        let plankcount = Math.floor(len / (this.plankgap + this.plankWidth));
        if (plankcount === 0)
            return;
        let startPos = this.lastplacedPlankPoint.clone();
        let endPos = point.clone();
        for (let i = 0; i < plankcount; i++) {
            let percent = i / plankcount;

            let mesh = this.entitiy.world.modelDatabase.database.RailPlank.scene.clone();
            let pos = Utilities.LerpV3WithReturn(startPos, endPos, percent);


            mesh.position.copy(pos)
            this.transform.add(mesh)
            mesh.lookAt(endPos.clone().add(dir))

            mesh.scale.x = randFloat(1, 1.25);
            mesh.scale.y = .09
            mesh.scale.z = this.plankWidth
        }
        this.lastplacedPlankPoint = point.clone()

    }


}