import Component from "../Engine/Core/Component";
import Utilities from "../Engine/Utilities";
import TrailPathComputer from "../Trail/TrailPathComputer";
import LiftObject from "../Player/LiftObject";
import CandyHelper from "../CandyHelper/CandyHelper";
import TrailPathPositioner from "../Trail/TrailPathPositioner";
import {gsap} from "gsap";
import PotSmoker from "./PotSmoker";

export default class MaxStation extends Component
{
    candyHelpers = []
    levelmanager
    StationEnterPath = [26.1466,0.197573,53.8608,25.421,0.197573,53.7292,24.0849,0.197573,53.7685,22.1637,0.197573,53.7242]
    stationEnabled = false
    stationEnterSplineComputer


    objectStartPositions = {


        maxicon001:[20.756,0.283447,51.413,0,0,0,1.49343,1.49343,1.49343],
        maxteapot001:[21.3827,0.215897,52.5715,0,0,0,1.0,1.0,1.0],
        maxhedra001:[20.6357,0.291543,52.5251,0,0,0,1.0,1.0,1.0],
        maxknot001:[19.7894,0.149076,52.5686,0,0,0,1.44505,1.44505,1.44505],
        Cylinder017:[20.0664,0.392532,53.3787,0,0,0,1.2709,1.2709,1.2709],

         
        StationTransform:[25.5391,0.0,48.0722,0,0.0,0.0,1.0,1.0,1.0],
    }

    objectTargetPositions =
        {

            maxhedra002:[0.231287,0.734705,-0.346281,-1.5708,0.0,0.0,0.496063,0.496063,0.496063],
            maxknot002:[0.317959,0.632323,-0.0522164,-1.5708,0.0,0.0,0.715986,0.715986,0.715986],
            Cylinder018:[0.257198,0.665596,0.131115,-1.5708,0.0,0.0,0.33301,0.33301,0.33301],
            
            Carrier:[-4.04436,0.25,0.0,-1.5708,0.0,0.0,1.0,1.0,1.0],            
            maxteapot002:[-0.182669,0.8,-0.209498,0,1.5,0,1,1,1],
            maxicon002:[0.13,1.10421,0,1.5,0,-1.5,0.85168,3,1],

        } 

    BuildStation() {
 
        let psStation = this;
        let stationModel = this.entitiy.world.modelDatabase.database.Station2.scene.clone();
        this.transform.add(stationModel);
    
        Utilities.DataToTransformApply(stationModel, this.objectStartPositions.StationTransform);
        this.stationEnterSplineComputer = Utilities.SpawnWithComponent("StationEnterSplineComputer", TrailPathComputer, this.entitiy.world);
        this.stationEnterSplineComputer.CreatePreDefinedPathPoints(this.StationEnterPath);
 
        let maxicon = Utilities.SpawnWithComponent("maxiconmodel", LiftObject, this.entitiy.world).Build(
            'maxicon',this.objectStartPositions.maxicon001,this.objectTargetPositions.maxicon002
        );
        let maxcylinder = Utilities.SpawnWithComponent("maxcylinder", LiftObject, this.entitiy.world).Build(
            'maxcylinder',this.objectStartPositions.Cylinder017,this.objectTargetPositions.Cylinder018
        );
        let maxhedra =Utilities.SpawnWithComponent("maxhedra", LiftObject, this.entitiy.world).Build(
            'maxhedra',this.objectStartPositions.maxhedra001,this.objectTargetPositions.maxhedra002
        );
        let maxknot =Utilities.SpawnWithComponent("maxknot", LiftObject, this.entitiy.world).Build(
            'maxknot',this.objectStartPositions.maxknot001,this.objectTargetPositions.maxknot002
        );
        let maxteapot = Utilities.SpawnWithComponent("maxteapot", LiftObject, this.entitiy.world).Build(
            'maxteapot',this.objectStartPositions.maxteapot001,this.objectTargetPositions.maxteapot002
        );
       let pothead=this.entitiy.world.modelDatabase.database.maxteapot.scene.children[1].clone();
        
       let smoker = maxteapot.entitiy.AddComponent(new PotSmoker());       
        maxteapot.model.add(pothead)        
        pothead.position.set(0,0,0) 
        pothead.rotation.set(0,0,0)
        smoker.Build(pothead,maxteapot.model)
        
        
        let objArr = [maxicon, maxcylinder, maxhedra, maxknot, maxteapot] 
        let points = []
        let pointsReverse = []
        for (let i = 0; i < this.stationEnterSplineComputer.PathPoints.length; i++) {
            points.push(this.stationEnterSplineComputer.PathPoints[i].transform.position)
            pointsReverse.push(this.stationEnterSplineComputer.PathPoints[this.stationEnterSplineComputer.PathPoints.length - i - 1].transform.position)
        }
        /*this.levelmanager.tug.CreateCarriers(2)
        for (let i = 0; i <objArr.length ; i++) {
            let carrier =psStation.levelmanager.tug.GetCarrier(1)
            carrier.transform.attach( objArr[i].model)
            objArr[i].MoveToTarget()
        }
        smoker.StartBoiling()
        return*/
        for (let i = 0; i <objArr.length ; i++) {
            let newHelper = Utilities.SpawnWithComponent("Candy_Helper_1", CandyHelper, this.entitiy.world);
            newHelper.transform.position.set(pointsReverse[0].x + 0.5, pointsReverse[0].y, pointsReverse[0].z + 0.5)
            let positioner = newHelper.entitiy.AddComponent(new TrailPathPositioner());
            positioner.Build(this.stationEnterSplineComputer)
            newHelper.pathPositioner = positioner;
            this.candyHelpers.push(newHelper)
            newHelper.CreateConditionWaitTask(psStation, 'stationEnabled');

            newHelper.CreateDelayTask(i*0.25);

            //First Move On Path
            pointsReverse.forEach(e =>
                newHelper.CreateMoveToPointTask(()=> {
                    return e
                },0.5,-1,()=>{
                    newHelper.animationController.animation.PlayFade('run',0.25)
                })
            )
            //Move to Lift Target
            newHelper.CreateMoveToPointTask(
                () => {                    
                    return objArr[i].model.position
                }, 0.1)

            //LiftOff Target
            newHelper.CreateDelayTask(0.5,()=>{
                newHelper.animationController.animation.PlayFade('liftoff',0.25)
                newHelper.transform.attach( objArr[i].model)
                if(objArr[i]===maxteapot)
                {
                    smoker.StartBoiling()
                }
                objArr[i].ReAssignMaterials();
                gsap.to(objArr[i].model.position,{x:0,y:1,z:0})

            });

            //Move To Carrier 
            newHelper.CreateMoveToPointTask(() => {
                return psStation.levelmanager.tug.GetCarrier(1).transform.position
            },2*2,3,()=>{
                newHelper.animationController.animation.PlayFade('liftoffrun',0.25)
            })
            //Throw to target
            newHelper.CreateDelayTask(0.4,()=>{
                newHelper.animationController.animation.PlayFade('throw',0.25)
            });
            newHelper.CreateDelayTask(0.5,()=>{
                let carrier =psStation.levelmanager.tug.GetCarrier(1)
                carrier.transform.attach( objArr[i].model)
                objArr[i].MoveToTarget()
                newHelper.animationController.animation.PlayFade('throw',0.25)
            });
            //Return To home
            points.forEach(e =>
                newHelper.CreateMoveToPointTask(()=> {
                    return e
                },0.5,-1,()=>{
                    newHelper.animationController.animation.PlayFade('run',0.25)
                })
            )
            newHelper.CreateDestroyCandyTask();
        }
    }

    StartStationAnim() {
        if(this.stationEnabled)
            return;
        //this.levelmanager.tug.CreateCarriers(1)
        this.stationEnabled = true;
    }
}