import Component from "../Engine/Core/Component";
import AnimationController from "../Engine/Components/AnimationSystem/AnimationController";
import * as THREE from "three";
import ShadowMaker from "../Player/ShadowMaker";
import CandyHelperTask from "./CandyHelperTask";
import Utilities from "../Engine/Utilities";
import {Vector2, Vector3} from "three";
import {randFloat} from "three/src/math/MathUtils";
import CPUParticleEmitter from "../Engine/Components/ParticleSystem/CPUParticleEmitter";

export default class CandyHelper extends Component {

    animationController
    meshRoot
    shadow
    tasks = []
    taskIndex = 0;
    pathPositioner
    movespeed = 4;
    rotationspeed =8;
    sleepEmitter


    /*
        idle
        liftoff
        liftoffrun
        runthrow
        walk
        */
    Start() {
        super.Start();
        let candyhelper = this;
        this.animationController = {}


        this.animationController.animation = {
            PlayFade: () => {
            }
        }
        this.shadow = this.entitiy.AddComponent(new ShadowMaker());
        this.entitiy.world.LoadGltfModel("Models/CandyHelper.glb",
            function (gltf) {

                gltf.scene.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        if (candyhelper.entitiy.world.materialDatabase.Database[child.material.name] !== undefined)
                            child.material = candyhelper.entitiy.world.materialDatabase.Database[child.material.name]
                    }
                })
                candyhelper.animationController = candyhelper.entitiy.AddComponent(new AnimationController());
                candyhelper.entitiy.transform.add(gltf.scene);
                candyhelper.animationController.Build(gltf);

                //candyhelper.animationController.modelRoot.scale.set(0.023, 0.023, 0.023)
                candyhelper.animationController.modelRoot.scale.set(1, 1, 1)
                candyhelper.meshRoot = candyhelper.animationController.modelRoot;
                candyhelper.meshRoot.rotateY(3.1415 * 2)
                candyhelper.shadow.Build(0.75, 0.75)
                candyhelper.animationController.animation.PlayFade('idle')
                candyhelper.animationController.DisableAnimationLoop('throw')
                candyhelper.animationController.DisableAnimationLoop('liftoff') 
            })
    }

    StartSleepAnim() {
        if (this.sleepEmitter === undefined)
            this.CreateSleepEmitter()
        this.sleepEmitter.emitPeriod = 1.25
        this.animationController.animation.PlayFade('Sleep',0.25)
    }

    StopSleepAnim() {
        if (this.sleepEmitter !== undefined)
            this.sleepEmitter.emitPeriod = 999999999
    }

    CreateSleepEmitter() {
        this.sleepEmitter = Utilities.SpawnWithComponent("SleepzEmitter", CPUParticleEmitter, this.entitiy.world)
        let geo = this.entitiy.world.modelDatabase.database.sleepz.scene.children[0].geometry;
        let mat = this.entitiy.world.materialDatabase.Database.sleepz;
        this.transform.add(this.sleepEmitter.transform);
        this.sleepEmitter.transform.position.y = 1;
        this.sleepEmitter.sizeRange = new Vector2(0.02, 0.04)
        this.sleepEmitter.poolsize = 10
        this.sleepEmitter.InitEmitter(geo, mat)
    }

    Update(delta) {
        if (this.taskIndex < 0)
            return
        if(this.tasks.length===0)
            return;
        this.tasks[this.taskIndex].Update(delta);
    }

    MoveNextTask() {
        this.taskIndex++
        if (this.taskIndex >= this.tasks.length)
            this.taskIndex = 0
        this.tasks[this.taskIndex].OnTaskStart();
    }


    CreateMoveToPointTask(targetPoint, distlen = 0.5, maxDuration = -1, onstart = () => {
    }, startcondition = true) {
        let candy = this;
        let task = new CandyHelperTask()
        task.targetPoint = targetPoint;
       
        task.transform = this.transform; 
        task.startCondition = startcondition
        task.maxDuration = maxDuration
        task.timer = 0

        task.Update = (delta) => {
            if (!task.startCondition)
                return;
            task.timer += delta;
            let dir = task.targetPoint().clone()
            dir.sub(task.transform.position)
            let dist = dir.lengthSq();
            dir.y = 0
            dir.normalize()
            let left = Utilities.GetDirVector(new Vector3(1, 0, 0), task.transform)
            let dot = dir.dot(left); 
            task.transform.translateZ(candy.movespeed * delta)
            task.transform.rotateY(delta * dot * candy.rotationspeed)
            if (dist <= distlen)
                task.OnTaskComplete();
            if (task.maxDuration > 0 && task.timer > task.maxDuration)
                task.OnTaskComplete();
        }
        task.OnTaskComplete = () => {
            candy.MoveNextTask()
        }
        task.OnTaskStart = onstart
        this.tasks.push(task)
    }

    CreateLiftUpTask(liftUpTarget) {

    }

    CreateFollowPathTask(followPath, moveForward = true, startcondition = true) {
        let candy = this;
        let task = new CandyHelperTask()
        task.startCondition = startcondition
        task.moveSpeed = candy.movespeed;
        task.moveForward = moveForward;
        task.pathpos = 0;
        task.pathlength = followPath.GetPathLength();


        task.OnTaskStart = () => {
            if (!task.moveForward)
                task.pathpos = task.pathlength
            candy.pathPositioner.lookback = !task.moveForward;
        }
        task.Update = (delta) => {
            if (moveForward) {

                candy.pathPositioner.PlaceOnPath(task.pathpos)
                task.pathpos += delta;
                if (task.pathpos >= task.pathlength)
                    task.OnTaskComplete()

            } else {

                candy.pathPositioner.PlaceOnPath(task.pathpos)
                task.pathpos -= delta;
                if (task.pathpos <= 0)
                    task.OnTaskComplete()
            }

        }
        task.OnTaskComplete = () => {
            candy.MoveNextTask()
        }
        task.OnTaskStart = () => {
            candy.animationController.animation.PlayFade('run', 0.25)
        }
        this.tasks.push(task)
    }

    CreateDelayTask(time = 1, onstart = () => {
    }, onend = () => {
    }) {
        let candy = this;
        let task = new CandyHelperTask()
        task.time = time;
        task.timer = 0;
        task.Update = (delta) => {
            task.timer += delta;
            if (task.timer >= task.time)
                task.OnTaskComplete()
        }
        task.OnTaskComplete = () => {
            candy.MoveNextTask()
            task.timer=0;
            onend()
        }
        task.OnTaskStart = () => {
            onstart()
        }
        this.tasks.push(task)
    }

    CreateConditionWaitTask(conditionObject, property, onstart = () => {
    }, onend = () => {
    }) {
        let candy = this;
        let task = new CandyHelperTask()
        task.Update = (delta) => {

            if (!conditionObject[property])
                return;

            task.OnTaskComplete()

        }
        task.OnTaskComplete = () => {
            candy.MoveNextTask()
            onend()
        }
        task.OnTaskStart = () => {
            onstart()
        }
        this.tasks.push(task)
    }

    CreateDestroyCandyTask() {
        let candy = this;
        let task = new CandyHelperTask()
        task.Update = (delta) => {
            task.OnTaskComplete()
        }
        task.OnTaskComplete = () => {
            candy.entitiy.Destroy();
        }
        this.tasks.push(task)
    }
    OnDestroy() {
        if (this.sleepEmitter !== undefined)
        {
            this.sleepEmitter.entitiy.Destroy()
        }
        super.OnDestroy();
    }
}