import Component from "../Engine/Core/Component";
import Utilities from "../Engine/Utilities";
import CandyHelper from "../CandyHelper/CandyHelper";
import TrailPathComputer from "../Trail/TrailPathComputer";
import TrailPathPositioner from "../Trail/TrailPathPositioner";
import LiftObject from "../Player/LiftObject";
import { gsap } from "gsap";
import {Euler} from "three";
export default class photoshopStation extends Component {
    candyHelpers = []
    levelmanager

    objectStartPositions = {


        TextT004:[49.5196,0.137656,27.6768,0.0741106,0.0,0.0,1.0,1.0,1.0],
        brush004:[50.1281,0.206933,26.9242,1.33158e-07,0.0,0.0,1.0,1.0,1.0],
        Stp002:[50.7082,0.160387,27.005,-1.5708,0.0,0.0,1.0,1.0,1.0],
        TextT002:[49.9314,0.160387,27.7149,-0.047739,-3.14159,0.0,1.0,1.0,1.0],
        TextT003:[49.683,0.160387,28.0401,0.0,2.0944,0.0,1.0,1.0,1.0],
        Bucket004:[51.048,0.160387,28.8269,-1.5708,0.0,0.0,1.0,1.0,1.0],
        Bucket003:[50.3278,0.160387,28.7715,-1.5708,0.0,0.0,1.0,1.0,1.0],
        brush003:[49.8399,0.185886,27.0692,1.33158e-07,-0.872665,0.0,1.0,1.0,1.0],
        ic003:[50.8424,0.215185,27.7753,-1.5708,0.0,0.0,1.0,1.0,1.0],
 
        StationTransform: [50.3189,0.0,22.7911,0,0.0,0.0,1.0,1.0,1.0],
    }

    objectTargetPositions =
        {

            ic002:[0.0744933,0.84868,0.0532371,-3.12006,0.566292,0.0,1.0,1.0,1.0],
            brush001:[-0.388231,0.541747,0.104596,-1.79769,0.0,0.0,1.0,1.0,1.0],
            brush002:[0.210678,0.541747,-0.28826,-1.5708,-3.01992e-07,-0.0819701,1.0,1.0,1.0],
            Bucket001:[-0.203472,0.374253,-0.297205,-1.5708,0.0,0.0,0.769275,0.769275,0.769275],
            TextT001:[-0.352063,0.517486,-0.033701,-3.04854,0.686231,2.96624,1.0,1.0,1.0],
            Stp001:[-0.0754221,0.294878,0.326249,-1.5708,0.0,0.0,1.0,1.0,1.0],
            Bucket002:[0.263793,0.38105,0.223332,-1.5708,0.0,0.0,0.799009,0.799009,0.799009],
            TextT005:[0.143843,0.366985,-0.304686,-3.07818,0.666293,3.13019,1.0,1.0,1.0],
            TextT006:[-0.417685,0.366985,0.0148475,-3.07818,1.18989,3.13019,1.0,1.0,1.0],


        }
    StationEnterPath = [46.0053,0.15865,27.3254,46.0053,0.15865,26.9613,46.0053,0.15865,25.7252,46.0255,0.15865,25.5506,46.0828,0.15865,25.3903,46.1728,0.15865,25.2488,46.2909,0.15865,25.1307,46.4324,0.15865,25.0407,46.5927,0.15865,24.9834,46.7673,0.15865,24.9632,48.3976,0.15865,24.9632]

    stationEnterSplineComputer
    stationEnabled = false

    BuildStation() {  
        
        let psStation = this;
        let stationModel = this.entitiy.world.modelDatabase.database.Station1.scene.clone();
        this.transform.add(stationModel);
        Utilities.DataToTransformApply(stationModel, this.objectStartPositions.StationTransform);
        this.stationEnterSplineComputer = Utilities.SpawnWithComponent("StationEnterSplineComputer", TrailPathComputer, this.entitiy.world);
        this.stationEnterSplineComputer.CreatePreDefinedPathPoints(this.StationEnterPath);
     
        
        
        let PsIconModel = Utilities.SpawnWithComponent("PsIconModel", LiftObject, this.entitiy.world).Build(
            'PhotoshopIcon',this.objectStartPositions.ic003,this.objectTargetPositions.ic002
        );        
        let PsBucked1 = Utilities.SpawnWithComponent("PsBucked1", LiftObject, this.entitiy.world).Build(
            'PhotoshopBucket',this.objectStartPositions.Bucket003,this.objectTargetPositions.Bucket001
        ); 
        let PsBucked2 =Utilities.SpawnWithComponent("PsBucked2", LiftObject, this.entitiy.world).Build(
            'PhotoshopBucket',this.objectStartPositions.Bucket004,this.objectTargetPositions.Bucket002
        );  
        let stmp =Utilities.SpawnWithComponent("stmp", LiftObject, this.entitiy.world).Build(
            'PhotoshopStamp',this.objectStartPositions.Stp002,this.objectTargetPositions.Stp001
        ); 
        let txt1 = Utilities.SpawnWithComponent("txt1", LiftObject, this.entitiy.world).Build(
            'PhotoshopText',this.objectStartPositions.TextT002,this.objectTargetPositions.TextT006
        ); 
        let txt2 = Utilities.SpawnWithComponent("txt2", LiftObject, this.entitiy.world).Build(
            'PhotoshopText',this.objectStartPositions.TextT003,this.objectTargetPositions.TextT005
        ); 
        let txt3 = Utilities.SpawnWithComponent("txt3", LiftObject, this.entitiy.world).Build(
            'PhotoshopText',this.objectStartPositions.TextT004,this.objectTargetPositions.TextT001
        ); 
        let brush1 = Utilities.SpawnWithComponent("brush1", LiftObject, this.entitiy.world).Build(
            'PhotoshopBrush',this.objectStartPositions.brush004,this.objectTargetPositions.brush001
        );
        let brush2 = Utilities.SpawnWithComponent("brush1", LiftObject, this.entitiy.world).Build(
            'PhotoshopBrush',this.objectStartPositions.brush003,this.objectTargetPositions.brush002
        ); 
        
        let objArr = [PsIconModel, PsBucked1, PsBucked2, stmp, txt1, txt2, txt3, brush1, brush2]
 
        let points = []
        let pointsReverse = []
        for (let i = 0; i < this.stationEnterSplineComputer.PathPoints.length; i++) {
            points.push(this.stationEnterSplineComputer.PathPoints[i].transform.position)
            pointsReverse.push(this.stationEnterSplineComputer.PathPoints[this.stationEnterSplineComputer.PathPoints.length - i - 1].transform.position)
        }  
        for (let i = 0; i <objArr.length ; i++) {
            let newHelper = Utilities.SpawnWithComponent("Candy_Helper_1", CandyHelper, this.entitiy.world);
            newHelper.transform.position.set(pointsReverse[0].x + 0.5, pointsReverse[0].y, pointsReverse[0].z + 0.5)
            let positioner = newHelper.entitiy.AddComponent(new TrailPathPositioner());
            positioner.Build(this.stationEnterSplineComputer)
            newHelper.pathPositioner = positioner;
            this.candyHelpers.push(newHelper)
            newHelper.CreateConditionWaitTask(psStation, 'stationEnabled');

            newHelper.CreateDelayTask(i*0.25);

            //First Move On Path
            pointsReverse.forEach(e =>
                newHelper.CreateMoveToPointTask(()=> {
                    return e
                },0.5,-1,()=>{
                    newHelper.animationController.animation.PlayFade('run',0.25)    
                })
            )
             //Move to Lift Target
            newHelper.CreateMoveToPointTask(
                () => {
                    return objArr[i].model.position
                }, 0.1)
            
            //LiftOff Target
            newHelper.CreateDelayTask(0.5,()=>{
                newHelper.animationController.animation.PlayFade('liftoff',0.25)
                newHelper.transform.attach( objArr[i].model)

                objArr[i].ReAssignMaterials();
                gsap.to(objArr[i].model.position,{x:0,y:1,z:0})
                
            });
            
            //Move To Carrier 
            newHelper.CreateMoveToPointTask(() => {
                return psStation.levelmanager.tug.GetCarrier(0).transform.position
            },2*2,3,()=>{
                newHelper.animationController.animation.PlayFade('liftoffrun',0.25)
            })
            //Throw to target 
            newHelper.CreateDelayTask(0.4,()=>{
                newHelper.animationController.animation.PlayFade('throw',0.25)
            });
            newHelper.CreateDelayTask(0.5,()=>{
                let carrier =psStation.levelmanager.tug.GetCarrier(0)
                carrier.transform.children[0].attach( objArr[i].model) 
                objArr[i].MoveToTarget()
                newHelper.animationController.animation.PlayFade('throw',0.25)
            });
            //Return To home
            points.forEach(e =>
                newHelper.CreateMoveToPointTask(()=> {
                    return e
                },0.5,-1,()=>{
                    newHelper.animationController.animation.PlayFade('run',0.25)
                })
            )
            newHelper.CreateDestroyCandyTask(); 
        }
    }

    StartStationAnim() {
        if(this.stationEnabled)
            return;
        this.levelmanager.tug.CreateCarriers(2)
        this.stationEnabled = true;
        
    }


}