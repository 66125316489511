import Component from "../Engine/Core/Component";
import Utilities from "../Engine/Utilities";
import {AxesHelper, BufferGeometry, CatmullRomCurve3, Line, LineBasicMaterial, Vector3} from "three";
import gsap from "gsap"
import * as THREE from "three";

export default class FreelanceStation extends Component {

    stationEnabled = false


    carriermesh
    baloonmesh
    endPositions = [
        new Vector3(-.386, .878, .352),
        new Vector3(0.386, .878, .352),
        new Vector3(0.386, .878, -.352),
        new Vector3(-.386, .878, -.352)
    ]
    lines = []
    baseposes = []
    lineCount = 55;
    infoPoint
    balloonmerged = false

    BuildStation() {
        let station = this;
        this.baloonmesh = this.levelmanager.modelDatabase.database.airbaloon.scene.clone();
        this.carriermesh = this.world.modelDatabase.database.CarrierBase.scene.clone();

        this.transform.add(this.baloonmesh);
        this.transform.add(this.carriermesh);
        this.carriermesh.rotation.y += 3.1415 * 0.5;


        for (let i = 0; i < this.lineCount; i++) {
            let basePos = new Vector3(0, 3.3, 0)
            let percent = i / this.lineCount;

            basePos.x = Math.sin((percent) * 2 * 3.1415);
            basePos.z = Math.cos((percent) * 2 * 3.1415);
            this.baseposes.push(basePos)

            let endposIndex = Math.min(3, Math.round(percent * 4));
            let endpos = this.endPositions[endposIndex].clone();

            let points = [basePos, endpos]
            let geometry = new BufferGeometry().setFromPoints(points);
            let material = new LineBasicMaterial({color: '#797979'});

            // Create the final object to add to the scene
            let line = new Line(geometry, material);
            line.frustumCulled = false;
            this.lines.push(line)
            this.transform.add(line)
        }
        let infodata = this.levelmanager.CreateInfoPointOnLine(
            'Baloon',
            'FreelancePanel',
            this.levelmanager.TriggerPositions.balloncome.t,
            this.levelmanager.TriggerPositions.balloncome.l,
            {x: 0, y: 0, z: 0}
        )
        this.infoPoint = infodata.point;

    }

    StartStationAnim() {
        if (this.stationEnabled)
            return;
        this.stationEnabled = true;
        this.infoPoint.EnableUIPoint();
    }

    async MergeBalloon() {
        if (this.balloonmerged)
            return 0;
        let resolve = function (time) {
            return new Promise(resolve => {
                setTimeout(function () {
                    resolve("fast ");
                }, time);
            });
        };


        this.balloonmerged = true;
        this.infoPoint.DisableUIPoint();
        this.lines.forEach(l => {
            this.transform.remove(l)
        })

        this.lines = undefined;

        this.levelmanager.tug.CreateCarriers(1, false);
        let targetCarrier = this.levelmanager.tug.GetLastCarrier();

        await resolve(15);
        targetCarrier.transform.attach(this.baloonmesh)

        gsap.to(this.baloonmesh.position, {x: 0, y: 0, z: 0, duration: 2})
        gsap.to(this.baloonmesh.rotation, {x: 0, y: 3.1415, z: 0, duration: 3})
        gsap.to(this.baloonmesh.scale, {x: 0.25, y: 0.25, z: 0.25, duration: 2, delay: 1})
        //this.baloonmesh.position.set(0,0,0)
        targetCarrier.cubeMesh.traverse(child => {
            if (child.isMesh) {
                child.visible = false;
            }
        })
        targetCarrier.cubeMesh.attach(this.carriermesh);
        gsap.to(this.carriermesh.position, {x: 0, y: 0, z: 0, duration: .5})
        gsap.to(this.carriermesh.rotation, {x: 0, y: 0, z: 0, duration: .5})
        gsap.to(this.carriermesh.scale, {x: 1, y: 1, z: 1, duration: .5})


    }

    RePositionLines() {
        for (let i = 0; i < this.lineCount; i++) {

            let percent = i / this.lineCount;
            let endposIndex = Math.min(3, Math.round(percent * 4));

            this.lines[i].geometry.attributes.position.array[0] = this.baseposes[i].x + this.baloonmesh.position.x;
            this.lines[i].geometry.attributes.position.array[1] = this.baseposes[i].y + this.baloonmesh.position.y;
            this.lines[i].geometry.attributes.position.array[2] = this.baseposes[i].z + this.baloonmesh.position.z;

            this.lines[i].geometry.attributes.position.array[3] = this.endPositions[endposIndex].x + this.carriermesh.position.x
            this.lines[i].geometry.attributes.position.array[4] = this.endPositions[endposIndex].y + this.carriermesh.position.y
            this.lines[i].geometry.attributes.position.array[5] = this.endPositions[endposIndex].z + this.carriermesh.position.z
            this.lines[i].geometry.attributes.position.needsUpdate = true;
        }
    }

    Update(delta) {
        if (!this.stationEnabled)
            return 0;
        if (this.balloonmerged)
            return 0;

        if (this.levelmanager.tug === undefined)
            return 0;
        let targetCarrier = this.levelmanager.tug.GetCarrier(2);
        if (targetCarrier === undefined)
            return 0;


        let targetpos = Utilities.GetDirVector(new Vector3(0, 0, 1), targetCarrier.transform);
        targetpos.multiplyScalar(4);
        targetpos.add(targetCarrier.transform.position)
        Utilities.LerpV3(this.baloonmesh.position, targetpos, delta);
        this.infoPoint.transform.position.set(
            this.baloonmesh.position.x,
            this.baloonmesh.position.y + 3,
            this.baloonmesh.position.z,
        )
        let carrierTargetPos = this.baloonmesh.position.clone();
        carrierTargetPos.y += 1.5;
        Utilities.LerpV3(this.carriermesh.position, carrierTargetPos, delta * 3);
        this.RePositionLines();
    }
}